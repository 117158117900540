import { computed, decorate, observable } from 'mobx';
import { Model, param } from '.';
import { USER_URL, doFetch } from '../../utils';

const URLS = {
  CONNECT_TO_BOARD: `${USER_URL}/api/user/device/login`,
};

class Device extends Model {
  constructor(context, data) {
    super(context, data);
    this.id = param(data, 'id');
    this.site = param(data, 'site');
    this.dev_state = param(data, 'dev_state', true);
    this.serialno = param(data, 'serialno', 'serialno');
    this.dev_id = param(data, 'dev_id');
    this.type_id = param(data, 'type_id');
    this.access_id = param(data, 'access_id');
    this.name = param(data, 'name');

    this.password = '';
  }

  toJS = () => {
    return {
      id: this.id,
      site: this.site,
      dev_id: this.dev_id,
      serialno: this.serialno,
      type_id: this.type_id,
      dev_state: this.dev_state,
      access_id: this.access_id,
      name: this.name,
      password: this.password,
    };
  };

  get displayName() {
    return `${this.dev_id}${this.type_id}`;
  }

  connect = () => {
    return doFetch(URLS.CONNECT_TO_BOARD, 'POST', { access_id: this.access_id });
  };
}

decorate(Device, {
  displayName: computed,
  id: observable,
  site: observable,
  dev_id: observable,
  dev_state: observable,
  type_id: observable,
  access_id: observable,
  name: observable,
  password: observable
})

export default Device;
