import { decorate, observable } from 'mobx';
import locales from '../locales.json';
import { openModal, getAccountInfo } from '../utils';

class Global {
  constructor() {
    this.lang = this.loadStoredLanguage();
    this.warningTitle = '';
    this.warningMessage = '';
    this.buttons = [];
    this.searchterm = '';

    this.refreshAccountName();

    this.languages = [
      {short: 'nl', long: 'Nederlands'},
      {short: 'fr', long: 'Francais'},
      {short: 'de', long: 'Deutsch'},
      {short: 'en', long: 'English'},
      {short: 'es', long: 'Espaniol'},
      {short: 'it', long: 'Italiano'},
      {short: 'pl', long: 'Polski'},
    ]
  }

  refreshAccountName = () => {
    const { accountFirstname, accountLastname } = getAccountInfo();
    this.accountName = `${accountFirstname} ${accountLastname}`
  };

  loadStoredLanguage = () => {
    let languageToLoad = 'en';
    const possibleLanguages = ['es', 'en', 'fr', 'de', 'it', 'nl', 'pl'];
    const localStorageResult = localStorage.getItem('_site_lang');

    if (localStorageResult && possibleLanguages.includes(localStorageResult.toLocaleLowerCase())) {
      languageToLoad = localStorageResult.toLowerCase();
    }

    return languageToLoad;
  };

  getLocale = code => {
    const match = locales.find(locale => locale.code === code);

    if (match) {
      return match.country;
    }

    return code;
  };

  updateLanguage = (lang) => {
    this.lang = lang;
    if (typeof Storage !== 'undefined') {
      localStorage.setItem('_site_lang', lang.toLowerCase());
    }
  };


  getDomain = () => {
    const host = new URL(window.location.href).hostname;
    const count = (host.match(/\./g) || []).length;

    return (host === 'localhost' || count <= 1)
      ? host
      : host.substr(host.indexOf('.') + 1, host.length);
  };


  displayWarningModal = (title, message, buttons) => {
    this.warningMessage = message;
    this.warningTitle = title;
    this.buttons = buttons;

    openModal('warningModal');
  };

  logout = async () => {
    const domain = this.getDomain();
    const cookieName = 'bc-token';
    const cookieValue = '';
    const myDate = Date.now();

    document.cookie = `${cookieName}=${cookieValue};expires=${myDate};domain=${domain};path=/`;
    window.location.href = 'https://sso.came.io';
  };
}

decorate(Global, {
  lang: observable,
  warningTitle: observable,
  warningMessage: observable,
  buttons: observable,
  languages: observable,
  searchterm: observable
});

export default new Global();
