import React, { useState }  from 'react';
import { FormattedMessage } from 'react-intl'
import { Button, Modal, Spinner } from '../elements';
import {useObserver} from 'mobx-react-lite';

const DeleteModal = ({ deleteDevice }) => {
  const [loading, setLoading] = useState(false);

  const performDeleteDevice = async () => {
    setLoading(true);
    await deleteDevice();
    setLoading(false);
  };

  return useObserver(() => {
    return (
      <Modal id="deleteDevice" className="modal modal-fixed-footer">
        <div className="modal-header">
          <h1>
            <FormattedMessage id="delete_device" />
          </h1>
        </div>
        <div className="modal-content">
          {
            !loading && (
              <p>
                Are you sure you want to delete your device?
              </p>
            )
          }

          {
            loading && (
              <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column'}}>
                <p>
                  Deleting your device
                </p>
                <Spinner />
              </div>
            )
          }
        </div>

        <div className="modal-footer">
          <Button className="btn-flat modal-close" text="cancel" />
          <Button className="btn-flat" onClick={performDeleteDevice} text="delete_device" />
        </div>
      </Modal>
    )
  });
};

export default DeleteModal;
