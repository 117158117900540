import React from 'react';
import { useObserver } from 'mobx-react-lite';
import { Input, Label } from '../elements';


const SiteForm = ({ entity }) => {
  return useObserver(() => {
    return (
      <div className="input">
        <Label htmlFor="site_name" />
        <Input entity={entity} type="text" name="name" placeholder="site_name_placeholder"/>
      </div>
    )
  });
};

export default SiteForm;
