import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useObserver } from "mobx-react-lite";

import { Messages } from '../I18n';
import { Modal, Button, Error } from '../elements';
import { useStores } from '../../hooks';
import { closeModal, controlOnErrors } from '../../utils';
import { SiteForm } from '../forms';
import { Cropper } from '../partials';
import styles from '../../assets/styles/siteModal.module.scss'

const VIEWS = {
  SET_NAME: 0,
  SET_IMAGE: 1,
  SET_IMAGE_OPTION: 2,
};

const NewSiteModal = () => {
  const { siteStore } = useStores(['siteStore']);
  const [ file, setFile ] = useState(undefined);
  const [ view, setView ] = useState(VIEWS.SET_NAME);
  const [ functions ] = useState({});

  const addSite = async () => {
    try {
      await controlOnErrors(siteStore.newSite);
      await siteStore.addSite();
      closeModal('newSite');
      Messages.success('added_new_site');
    } catch (err) {
      console.log(err);
      if (!Array.isArray(err)) {
        Messages.error('failed_to_add_site');
      }
    }
  };

  const close = () => {
    closeModal('newSite');
  };

  const onClose = () => {
    const { newSite } = siteStore;
    setView(VIEWS.SET_NAME);
    newSite.cancel();
  };

  const changeImage = ev => {
    if (ev.target.files && ev.target.files.length > 0) {
      setFile(ev.target.files[0])
    }

    setView(VIEWS.SET_IMAGE)
  };

  const proceed = async () => {
    const { newSite } = siteStore;

    switch(view) {
      case VIEWS.SET_NAME:
        await controlOnErrors(newSite);
        setView(VIEWS.SET_IMAGE_OPTION);
        break;

      case VIEWS.SET_IMAGE_OPTION:
        setView(VIEWS.SET_IMAGE);
        break;

      case VIEWS.SET_IMAGE:
        const cropData = functions.getCropData();
        newSite.image = cropData;
        addSite();
        break;
    }
  };

  return useObserver(() => {
    const { newSite } = siteStore;

    return (
      <Modal id="newSite" onClose={onClose}>
        <div className="modal-header">
          <h1>
            <FormattedMessage id="new_site" values={{ currentStep: view + 1, amountOfSteps: 2 }} />
          </h1>

          <i className="fas fa-times" onClick={close} />
        </div>

        <div className="modal-content">
          {
            view === VIEWS.SET_NAME && (
              <>
                <Error activeErrors={newSite.activeErrors}/>
                <SiteForm entity={newSite} inModal />
              </>
            )
          }

          {
            view === VIEWS.SET_IMAGE_OPTION && (
            <>
              <span>
                <FormattedMessage id="do_you_want_to_select_an_image_for_your_site" />
              </span>

              <input id="add_site_file" type="file" onChange={ev => changeImage(ev)} />

              <div className={styles.options}>
                <label htmlFor="add_site_file" className={styles.option}>
                  <FormattedMessage id="yes_select_an_image" />
                </label>

                <div 
                  className={styles.option}
                  onClick={addSite}
                >
                  <FormattedMessage id="no_add_my_site" />
                </div>
              </div>
            </>
            )
          }

          {
            view === VIEWS.SET_IMAGE && (
              <Cropper 
                entity={newSite} 
                selectedFile={file} 
                functions={functions}
              />
            )
          }
        </div>

        <div className="modal-footer">

          {
            view === VIEWS.SET_NAME && (
              <Button text="proceed" onClick={proceed}/>
            )
          }

          {
            view === VIEWS.SET_IMAGE && (
              <Button text="use_image_and_save_site" onClick={proceed}/>
            )
          }

          {
          }
          <Button text="cancel" onClick={close}/>
        </div>
      </Modal>
    )
  })
};

export default NewSiteModal;

