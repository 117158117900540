import React, { Component, Fragment } from 'react';
import { observer, inject } from 'mobx-react';
import { Input, Label } from '../elements';

@inject('settingsStore')
@observer
export default class DeviceForm extends Component {
  render() {
    const { entity } = this.props;
    const { oldPassword, password, repeatPassword } = entity;

    return (
      <Fragment>
        <div className="input">
          <Label htmlFor="oldPassword" />
          <Input context={this} id="oldPassword" type="password" name="oldPassword" value={oldPassword} />
        </div>

        <div className="input">
          <Label htmlFor="password" />
          <Input context={this} id="password" type="password" name="password" value={password} />
        </div>

        <div className="input">
          <Label htmlFor="repeat_password" />
          <Input context={this} id="repeat_password" type="password" name="repeatPassword" value={repeatPassword} />
        </div>
      </Fragment>
    );
  }
}
