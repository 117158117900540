import React, { useEffect, useState } from 'react';
import { useObserver } from 'mobx-react-lite';
import { Info } from '../elements';
// import 'react-image-crop/dist/ReactCrop.css';

import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";

const MyCropper = ({ selectedFile, functions }) => {
  const [cropper, setCropper] = useState();
  const [src, setSrc] = useState();

  const getCropData = () => {
    return cropper.getCroppedCanvas({
      width: 255,
      height: 255
    }).toDataURL('image/jpeg', 0.7);
  };

  useEffect(() => {
    functions.getCropData = getCropData;
  }, [cropper])

  useEffect(() => {
    if (selectedFile !== undefined) {
      const reader = new FileReader();
      reader.addEventListener('load', () => setSrc(reader.result));
      reader.readAsDataURL(selectedFile);
    }
  }, [selectedFile]);


  return useObserver(() => (
    <>
      <Info 
        info="cropper_info"
      />

      <Cropper
        style={{ height: 400, width: "100%" }}
        zoomTo={0.5}
        preview=".img-preview"
        src={src}
        viewMode={1}
        minCropBoxHeight={10}
        minCropBoxWidth={10}
        background={false}
        aspectRatio={1}
        responsive={true}
        autoCropArea={1}
        checkOrientation={false} // https://github.com/fengyuanchen/cropperjs/issues/671
        onInitialized={(instance) => {
          console.log(instance, 'instance')
          setCropper(instance);
        }}
        guides={true}
      />
    </>
  ));
};

export default MyCropper;
