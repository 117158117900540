import { observable } from 'mobx';
import { translate } from '../I18n';

export default class Option {
  @observable id;
  @observable name;
  @observable active;

  constructor(viewModel, id, name, image, color) {
    this.id = id;
    this.name = name;
    this.image = image;
    this.active = true;
    this.translation = translate(name);
    this.color = color;
  }

  toggle = () => {
    this.active = !this.active;
  }
}
