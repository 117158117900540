import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useObserver } from "mobx-react-lite"

const Error = ({ error = '', activeErrors = [] }) => {
  return useObserver(() => {
    if (error !== '' || activeErrors.length) {
      return (
        <section className="errorinfo">
          <h2 className="errortitle">
            <FormattedMessage id="please_resolve_the_following_errors" />
          </h2>

          <ul>
            {
              error !== '' && (
                <li>
                  <FormattedMessage id={error} />
                </li>
              )
            }

            {
              activeErrors.map((error, index) => (
                <li key={index}>
                  <FormattedMessage id={error.message} />
                </li>
              ))
            }
          </ul>
        </section>
      )
    }

    return null;
  })
}

export default Error;
