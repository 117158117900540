import { observable } from 'mobx';
import { translate } from '../I18n';

export default class Column {
  @observable columnName;
  @observable status;
  @observable dbColName;

  constructor(context, columnName, dbColName, status = 2) {
    this.context = context;
    this.columnName = translate(columnName);
    this.status = status;
    this.dbColName = dbColName;
    this.statusOptions = ['sort-desc', 'sort-asc', 'sort'];
    this.displayStatus = this.statusOptions[status];
  }

  changeStatus = () => {
    this.status = ((this.status === 2) ? 0 : this.status + 1);
    this.displayStatus = this.statusOptions[this.status];
    this.context.searchWithFilters();
    this.context.forceUpdate();
  };
}
