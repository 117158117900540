import React from 'react';
import { useObserver } from 'mobx-react-lite';
import { FormattedMessage } from 'react-intl';

import { Modal, Info, Error, Label, Input, Button } from '../elements';
import { closeModal, controlOnErrors } from '../../utils';
import { useStores, useError } from '../../hooks';
import { Messages } from '../I18n';

const TransferSiteModal = () => {
  const { siteStore, settingsStore } = useStores(['siteStore', 'settingsStore']);
  const { error, setError, clearError } = useError('');

  const close = () => {
    closeModal('transferSite');
  };

  const transferSite = async () => {
    try {
      const { account } = settingsStore;

      await controlOnErrors(account, account.transferValidation);
      await siteStore.transferSite(account.password, account.newEmail);
      Messages.success('the_invite_for_the_transfer_has_been_sent');
      close();
    } catch (err) {
      console.log(err);
      if (err?.status) {
        switch (err?.status) {
          case 401:
            setError('invalid_password_supplied');
            break;
          case 409:
            setError('a_new_owner_already_has_been_invited');
            break;
          default:
            setError('error_occured_in_the_server');
        }
      }
    }
  };

  const onClose = () => {
    clearError();
    const { account } = settingsStore;
    account.cancel();
  };

  return useObserver(() => {
    const { selectedSite } = siteStore
    const { account } = settingsStore;

    return (
      <Modal id="transferSite" onClose={onClose}>
        <div className="modal-header">
          <h1>
            <FormattedMessage id="transfer_site_title" values={{name: selectedSite.name}}/>
          </h1>
          <i className="fas fa-times" onClick={close} />
        </div>

        <div className="modal-content">
          <Info info="info_transfer_site" />

          <Error error={error} activeErrors={account.activeErrors}/>

          <div>
            <Label htmlFor="enter_the_email_of_the_new_account"/>
            <Input entity={account} name="newEmail" placeholder="enter_the_email_of_the_new_account" />
          </div>

          <div>
            <Label htmlFor="enter_your_password"/>
            <Input type="password" entity={account} name="password" placeholder="enter_your_password" />
          </div>
        </div>

        <div className="modal-footer">
          <Button text="transfer_site" onClick={transferSite}/>
        </div>
      </Modal>
    )
  });
};

export default TransferSiteModal;

