import React from 'react';
import { observer } from 'mobx-react';
import { FormattedMessage } from 'react-intl';
import { Messages } from '../I18n';

export default observer(({ onClick, text, icon, className = '', disabled = false, disabled_message, ...rest }) => {

  const execute = (ev) => {
    if (!disabled) {
      onClick(ev);
    } else if (disabled && disabled_message !== undefined){
      Messages.error(disabled_message)
    }
  };

  return (
    <div 
      className={`btn ${className} ${disabled && 'disabled'}`} 
      onClick={execute} 
      style={icon !== undefined ? { display: 'flex', justifyContent: 'center', alignItems: 'center' } : {}} 
      {...rest} >
      {
        icon !== undefined && (
          <i className={icon} style={{ marginRight: '10px' }} />
        )
      }
      <FormattedMessage id={text} />
    </div>
  );
});
