import React from 'react';
import { useObserver } from 'mobx-react-lite';
import { FormattedMessage } from 'react-intl';
import { setValue, getValue, validate } from './IO';

const Switch = props => { 
  const { onLabel = 'on', offLabel = 'off', style = {} } = props;

  const changeState = state => {
    setValue(props, state);
    validate(props, state);
  };

  return useObserver(() => { 
    const value = getValue(props);

    return (
      <div className="switch" style={style}>
        <div 
          className={value ? "active" : ""}
          onClick={() => changeState(true)}
        >
          <FormattedMessage id={onLabel} />
        </div>
        <div 
          className={value ? "" : "active"}
          onClick={() => changeState(false)}
        >
          <FormattedMessage id={offLabel} />
        </div>
      </div>
    )
  })
}
export default Switch;
