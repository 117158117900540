import React, { useState }  from 'react';
import { FormattedMessage } from 'react-intl'
import { Button, Error, Info, Modal } from '../elements';
import { ClaimForm } from '../forms';
import { Spinner } from '../elements';
import { useObserver } from 'mobx-react-lite';
import { closeModal, controlOnErrors } from '../../utils';
import { useError, useStores } from '../../hooks';
import { Messages } from '../I18n';

import enterTheAccessCode from '../../assets/icons/reader.svg';
import connectToPower from '../../assets/icons/acs01.svg';
import connectToInternet from '../../assets/icons/network.svg';
import styles from '../../assets/styles/newDevice.module.scss';

const ReplaceDeviceModal = () => {
  const [loading, setLoading ] = useState(false);
  const { siteStore } = useStores(['siteStore']);
  const { error, setError, clearError } = useError();

  const close = () => {
    closeModal('replaceDeviceModal');
  };

  const replace = async () => {
    const { newDevice, selectedSite } = siteStore;
    try {
      await controlOnErrors(newDevice);
      clearError();
      setLoading(true);
      await siteStore.replaceDevice();
      await siteStore.getDevicesFromSite(selectedSite.id);
      Messages.success('replaced_device');
      setLoading(false);
      close();
    } catch (err) {
      const { status } = err;
      setLoading(false);
      if (status !== undefined) {
        switch (status) {
          case 410:
            setError('The device you want to claim is already claimed by someone else');
            break;

          case 503:
            setError('The device you want to claim is not online. Make sure it\'s online');
            break;

          case 412:
            setError('One or more devices are offline. Make sure all the devices from this site are online');
            break;

          default:
            setError('Unknown error');
            break;
        }
      }
    }
  };

  const onClose = () => {
    const { newDevice } = siteStore;
    clearError();
    newDevice.cancel();
  };

  return useObserver(() => {
    const { newDevice, selectedDevice } = siteStore;

    return (
      <Modal id="replaceDeviceModal" onClose={onClose}>
        <div className="modal-header">
          <h1>
            <FormattedMessage id="replace_device_title" values={{ serialno: selectedDevice.serialno }}/>
          </h1>

          <i className="fas fa-times" onClick={close}/>
        </div>

        <div className="modal-content">
          {
            !loading && (
              <>
                <Error activeErrors={newDevice.activeErrors} error={error}/>
                <div className={styles.icons}>
                  <figure>
                    <div className={styles.step}>1</div>
                    <img src={connectToPower} alt="connect to power" />
                    <figcaption>
                      <FormattedMessage id="connect_to_power" />
                    </figcaption>
                  </figure>

                  <figure>
                    <div className={styles.step}>2</div>
                    <img src={connectToInternet} alt="connect to internet" />
                    <figcaption>
                      <FormattedMessage id="connect_to_internet" />
                    </figcaption>
                  </figure>

                  <figure>
                    <div className={styles.step}>3</div>
                    <img src={enterTheAccessCode} alt="Enter the access code" />
                    <figcaption>
                      <FormattedMessage id="enter_the_access_code" />
                    </figcaption>
                  </figure>
                </div>
                <ClaimForm entity={newDevice} />
              </>
            )
          }

          {
            loading && (
              <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column'}}>
                <p style={{fontSize: '1.3rem', marginTop: '30px', marginBottom: '40px'}}>
                  <FormattedMessage id="replacing_your_device" />
                </p>
                <Spinner />
              </div>
            )
          }
        </div>

        <div className="modal-footer">
          {
            !loading && (
              <>
                <Button onClick={close} text="cancel" />
                <Button onClick={replace} text="replace_device" />
              </>
            )
          }
        </div>
      </Modal>
    )
  });
};

export default ReplaceDeviceModal;

