import { IntlProvider } from 'react-intl';
import { toast } from 'react-toastify';
import locales from "../assets/locales/locales.json";

export const translate = (string, values) => {
  const locale = localStorage.getItem('_site_lang') || 'en';

  const keys = Object.keys(locales);
  const objvalues = Object.values(locales);

  const messages = keys.reduce((obj, key, index) => {
    obj[key] = objvalues[index][locale.toLowerCase()];
    return obj;
  }, {});

  const intlProvider = new IntlProvider({ locale, messages});
  const { intl } = intlProvider.getChildContext();
  return intl.formatMessage({id: string}, values);
};

export class Messages {
  static error = (message, values, closetime = 2998) => {
    toast.error(translate(message, values), { position: toast.POSITION.TOP_CENTER, autoClose: closetime });
  };

  static success = (message, values, closetime = 2000) => {
    toast.success(translate(message, values), { position: toast.POSITION.TOP_CENTER, autoClose: closetime });
  };

  static short = (message, values) => {
    toast.success(translate(message, values), { position: toast.POSITION.TOP_CENTER, autoClose: 1000 });
  };
}
