export const handler = (props, key, param) => {
  const { entity, name, afterChange } = props;

  if (name.includes('.')) {
    const keys = name.split('.');

    return keys.reduce((obj, child, index) => {
      if (index + 1 === keys.length) {

        if (key === 'update') {
          const oldValue = obj[child];
          obj[child] = param;

          if (afterChange !== undefined) {
            afterChange({ oldValue, newValue: param});
          }
        }
      }

      return obj[child];
    }, entity);

  } else {
    switch(key) {
      case 'update':
        const oldValue = entity[name];
        entity[name] = param;

        if (afterChange !== undefined) {
          afterChange({ oldValue, newValue: param });
        }

        break;

      case 'getErrorMessage':
        return entity[`error${name.replace(/\./g, '')}`];

      case 'validate':
        try {
          const errorResult = entity.validation[name](param);
          entity[`error${name.replace(/\./g, '')}`] = errorResult;
          return errorResult;
        } catch {
          console.error(`valdation for ${name} is not set`)
        }

        break;

      default:
        return entity[name];
    }
  }
}

export const getValue = props => {
  return handler(props, 'getValue');
};

export const getErrorMessage = props => {
  return handler(props, 'getErrorMessage');
};

export const validate = (props, value) => {
  return handler(props, 'validate', value);
};

export const  setValue = (props, value) => {
  return handler(props, 'update', value);
};

