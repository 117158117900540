import React from 'react';
import { useObserver } from 'mobx-react-lite';
import {useStores} from '../../hooks';
import { Label, Input, List } from '../elements';


const ClaimForm = ({ entity }) => {
  const { siteStore } = useStores(['siteStore']);

  return useObserver(() => {
    const { memberConnectionMethods } = siteStore;

    return (
      <>
        <div className="input">
          <Label htmlFor="label_name_device_claim" />
          <Input entity={entity} id="name" name="name" placeholder="placeholder_name_device_claim" />
        </div>

        <div className="input">
          <Label htmlFor="label_access_id_device_claim" />
          <Input entity={entity} id="access_id" name="access_id" placeholder="placeholder_access_id_device_claim" />
        </div>

        <div className="input">
          <Label htmlFor="enter_your_password" />
          <Input entity={entity} name="password" placeholder="enter_your_password" type="password"/>
        </div>

        <div>
          <Label htmlFor="connection_method" />
          <List entity={entity} list={memberConnectionMethods} name="connection_method" text="translation" value="id" />
        </div>  

      </>
    )
  });
};

export default ClaimForm;
