import React, { useState, useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { useObserver } from 'mobx-react-lite';
import logo from '../../assets/images/came-footer-logo.png';
import bluecherry_logo from '../../assets/images/bluecherry-footer-logo-white.svg';

import styles from '../../assets/styles/footer.module.scss';
import { useRedirect, useStore } from '../../hooks';

const Footer = () => {
  const { redirect } = useRedirect();
  const { account } = useStore("settingsStore");

  const [clock, setClock] = useState('');

  const addPadding = val => {
    return val < 10 ? `0${val}` : val;
  };

  const tick = () => {
    const time = new Date();

    const hours = addPadding(time.getHours());
    const minutes = addPadding(time.getMinutes());
    const seconds = addPadding(time.getSeconds());
    
    const day = addPadding(time.getDate());
    const month = addPadding(time.getMonth() + 1); 
    const year = time.getFullYear();
    
    setClock(`${day}/${month}/${year} - ${hours}:${minutes}:${seconds}`);
  };

  useEffect(() => {
    const interval = setInterval(() => {
      tick()
    }, 1000);

    return () => {
      clearInterval(interval);
    }
  }, []);

  return useObserver(() => {
    return (
      <footer className={styles.footer}>
        <div className={styles.footerContent}>
          <div className={styles.footerInfo}>
            <div className={styles.links}>
              <ul>
                <li onClick={() => redirect('/legal/pp')}>
                  <FormattedMessage id="privacy_statement" />
                </li>
                <li onClick={() => redirect('/legal/gc')}>
                  <FormattedMessage id="general_conditions" />
                </li>
                <li onClick={() => redirect('/legal/tou')}>
                  <FormattedMessage id="terms_of_use" />
                </li>
                <li onClick={() => redirect('/legal/cp')}>
                  <FormattedMessage id="cookie_usage" />
                </li>
              </ul>
            </div>

            <div className="logos">

              <div className="footer-image">
                <img src={bluecherry_logo} alt="footer logo" />
              </div>
              <div className="footer-image">
                <img src={logo} alt="footer logo" />
              </div>
            </div>
          </div>
          <div className={styles.copyright}>
            <FormattedMessage id="copyright" values={{ year: new Date().getYear() + 1900 }}/>
            <span>{ clock }</span>
          </div>
        </div>
      </footer>
    )

  })
};

export default Footer;

// @inject('global')
// @observer
// export default class Footer extends Component {
//   constructor() {
//     super();

//     this.state = {
//       clockTime: (new Date().getTime() / 1000) + 3600,
//       clock: '',
//     };
//   }

//   addPadding = val => {
//     return val < 10 ? `0${val}` : val;
//   };

//   tick = () => {
//     const { clockTime } = this.state;
//     const time = new Date((clockTime + 1) * 1000);

//     const hours = this.addPadding(time.getUTCHours());
//     const minutes = this.addPadding(time.getUTCMinutes());
//     const seconds = this.addPadding(time.getUTCSeconds());

//     const day = this.addPadding(time.getUTCDate());
//     const month = this.addPadding(time.getUTCMonth());
//     const year = time.getUTCFullYear();

//     this.setState({
//       clock: ` ${day}/${parseInt(month, 10) + 1}/${year}   -   ${hours}:${minutes}:${seconds}`,
//       clockTime: Math.round(time.getTime() / 1000),
//     });
//   };

//   componentDidMount = () => {
//     this.interval = setInterval(this.tick, 1000);
//   };

//   componentWillUnmount = () => {
//     clearInterval(this.interval);
//   };

//   redirect = () => {
   
//   };

//   render() {
//     const { clock } = this.state;
//     return (
      
//     )
//   }
// }
