import jwtdecoder from 'jwt-decode';
import modalStore from './stores/modalStore';

export const SSO_URL = '';
export const USER_URL = '';
export const URL = '';

export const wait = (time = 5000) => {
  return new Promise((resolve, reject) => setTimeout(resolve, time));
};

export const supressErrorNames = () => {
  if (process.env.NODE_ENV !== 'production') {
    const originalConsoleError = console.error;
    if (console.error === originalConsoleError) {
      console.error = (...args) => {
        if (args[0].indexOf('[React Intl] Cannot format message:') === 0 || args[0].indexOf('[React Intl] Missing message:') === 0) {
          return;
        }
        originalConsoleError.call(console, ...args);
      };
    }
  }
};

export const getAccountInfo = () => {
  const value = `; ${document.cookie}`;
  const parts = value.split('; bc-token=');
  try {
    if (parts.length === 2) {
      return jwtdecoder(parts.pop().split(';').shift());
    }

    window.location.href = 'https://sso.came.io';
  } catch (e) {
    window.location.href = 'https://sso.came.io';
  }
};

/* eslint-disable */
const asyncForEach = async function (array, callback) {
  for (let index = 0; index < array.length; index += 1) {
    /* eslint-disable no-await-in-loop */
    await callback(array[index], index, array);
  }
};


export const controlOnErrors = async (entity, validation) => {
  const keys = Object.keys(validation !== undefined ? validation : entity.validation);
  const errors = [];
  let hasErrors = false;

  await asyncForEach(keys, async (key) => {
    const valueToCheck = key.includes('.')
      ? key.split('.').reduce((obj, child) => obj[child], entity)
      : entity[key]


    const result = (validation !== undefined)
      ? await validation[key](valueToCheck)
      : await entity.validation[key](valueToCheck);


    entity[`error${key.replace(/\./g, '')}`] = result;

    if (result.error) {
      errors.push({ key, error: result.message });
      hasErrors = true;
    }
  });

  return new Promise((resolve, reject) => {
    if (hasErrors) {
      reject(errors);
    } else {
      resolve(errors);
    }
  });
};


export const openModal = modalName => {
  modalStore.openModal(modalName);
};

export const closeModal = modalName => {
  modalStore.closeModal(modalName);
};

export const handleError = (error, onServerError) => {
  onServerError(error.status, error.body);
};


export const fetchV1 = async (url, method, body) => {
  const options = {
    method,
    credentials: 'include',
    headers: {
      Accept: 'application/json, text/plain, */*',
      'Content-Type': 'application/json',
      'Accept-Bc-Version': 'v2',
    },
    body: JSON.stringify(body),
  };

  const response = await fetch(url, options);
  const textResponse = await response.text();

  try {
    const json = JSON.parse(textResponse);

    if (response.status === 401 && json.reason === 1) {
      window.location.href = window.location.href.replace('user', 'sso');
    } 

    return {
      body: json,
      statusCode: response.status
    };
  } catch (err) {
    return {
      body: textResponse,
      statusCode: response.status
    }
  }
};

export const doFetch = async (url, method, body) => {
  class ErrorHandler {
    constructor(status, message, body = {}) {
      this.status = status;
      this.message = message;
      this.body = body;
    }
  }

  const options = {
    method,
    credentials: 'include',
    headers: {
      Accept: 'application/json, text/plain, */*',
      'Content-Type': 'application/json',
      'Accept-Bc-Version': 'v2',
    },
  };

  if (body !== null && body !== undefined) {
    options.body = JSON.stringify(body);
  }


  const response = await fetch(url, options);
  const textResponse = await response.text();

  try {
    const json = JSON.parse(textResponse);
    if (response.status === 401 && json.reason === 1) {
      window.location.href = window.location.href.replace('user', 'sso');
    } else if (response.status >= 400) {
      throw new ErrorHandler(response.status, json.message, json);
    } 

    return json;
  } catch (err) {
    if (!err?.constructor?.name === 'ErrorHandler') {
      if (response.status !== 200) {
        throw new ErrorHandler(response.status, 'internal server error');
      }

      return textResponse
    }

    if (response.status === 200) {
      return textResponse
    }

    throw err;
  }
};


