import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'mobx-react';
import App from './components/App';
import { unregister } from './registerServiceWorker';
import * as stores from './stores';


const Root = () => {
  return (
    <Provider {...stores}>
      <App />
    </Provider>
  )
};

ReactDOM.render(<Root />, document.getElementById('root'));
unregister();
