import React, { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import { useObserver } from 'mobx-react-lite';

import { getAccountInfo, openModal } from '../../utils';
import { WarningModal } from '.';
import { NotificationModal } from '../modals';
import { useStores, useWidthValidator } from '../../hooks';

import logo from '../../assets/images/header-logo.png';
import styles from '../../assets/styles/header.module.scss';

const Header = () => {
  const [ displayName, setDisplayName ] = useState('');
  const [ showLanguageDropdown, setIsDropdownOpen ] = useState(false);
  const [ showMobileLangDropdown, setMobileLangDropdown ] = useState(false);
  const { global, notificationStore } = useStores(['notificationStore', 'global']);
  const [isSidenavOpen, setIsSidenavOpen ] = useState(false);
  const { isMobile } = useWidthValidator();
  const { languages } = global;
  
  useEffect(() => {
    const { accountFirstname, accountLastname } = getAccountInfo();
    setDisplayName(`${accountFirstname} ${accountLastname}`);
  }, []);

  const logout = () => {
    global.logout();
  };

  const openMessages = () => {
    openModal('notificationModal')
  };

  const getLanguage = lang => {
    return languages.find(language => language.short === lang)?.long;
  };

  const closeSidenav = () => {
    setIsSidenavOpen(false);
  };

  const openSidenav = () => {
    setIsSidenavOpen(true);
  };

   const toggleLanguageDropdown = () => {
     setMobileLangDropdown(!showMobileLangDropdown);
  };

  const changeLanguage = ev => {
    const lang = ev.target.getAttribute('data-id');
    global.updateLanguage(lang.toLowerCase());
  };


  const LanguageDropdown = () => {
    return (
      showLanguageDropdown && (
        <>
          <div className={styles.overlay} onClick={() => setIsDropdownOpen(false)}/>
          <ul className={styles.languageDropdown}>
            {
              languages.map(language => {
                return (
                  <li data-id={language.short} onClick={changeLanguage} key={language.short}>
                    {language.long}
                  </li>
                )
              })
            }
          </ul>  
        </>
      )
    )
  };

  return useObserver(() => {
    const { lang, warningTitle, warningMessage, buttons } = global;
    const { notifications } = notificationStore;
    const amountOfUnreadNotifictations = notifications.filter(notification => notification.state !== 'VIEWED');

    return (
      <>
        <NotificationModal />
        <WarningModal warningTitle={warningTitle} warningMessage={warningMessage} buttons={buttons} />

        {
          isMobile && (
            <>
              <header className={styles.mobileHeader}>
                <nav>
                  <i className="fas fa-bars" onClick={openSidenav}/>

                  <Link className="brand-logo" to="/">
                    <img src={logo} alt="Logo" width="120" />
                  </Link>

                  <div className="bell" onClick={openMessages}>
                    <i className="fas fa-bell" aria-hidden="true" />
                    {
                      amountOfUnreadNotifictations.length > 0 && (
                        <div className="amount">
                          <span>{ amountOfUnreadNotifictations.length }</span>
                        </div>
                      )
                    }
                  </div>
                </nav>
              </header>  

              {
                isSidenavOpen && (
                  <div className={styles.sidenavOverlay} onClick={closeSidenav} />
                )
              }

              <aside className={styles.aside} style={{
                  maxWidth: isSidenavOpen ? '300px' : 0,
                  display: !isSidenavOpen ? 'none': ''
                }}>
               <ul>
                 <li className={styles.accountName}>
                   <Link to="/account_settings">
                     {displayName}
                   </Link>
                 </li>

                 <li>
                   <Link to="/account_settings">
                     <FormattedMessage id="account_settings" />
                   </Link>
                 </li>

                  <li>
                    <Link to="/">
                      <FormattedMessage id="sites" />
                    </Link>
                  </li>

                  <li onClick={toggleLanguageDropdown}>
                    <Link to="#">
                      <FormattedMessage id="languages" />
                      <i className="fas fa-chevron-down" />
                    </Link>
                  </li>

                 {
                   showMobileLangDropdown && (
                     <>
                       {
                         languages.map(language => {
                           return (
                             <li data-id={language.short} onClick={changeLanguage} key={language.short} className={styles.langOption}>
                               {language.long}
                             </li>
                           )
                         })
                       }
                     </>
                   )
                 }

                 <li onClick={logout}>
                   <Link to="#">
                     <FormattedMessage id="logout" />
                   </Link>
                 </li>

                </ul>
              </aside>
            </>
          )
        }
        {
          !isMobile && (
            <header className={styles.header}>

              <nav>
                <div className={styles.left}>
                  <Link className="brand-logo" to="/">
                    <img src={logo} alt="Logo" width="120" />
                  </Link>
                </div>

                <ul>

                  <li onClick={openMessages}>
                    <div className="bell">
                      <i className="fas fa-bell" aria-hidden="true" />
                      {
                        amountOfUnreadNotifictations.length > 0 && (
                          <div className="amount">
                            <span>{ amountOfUnreadNotifictations.length }</span>
                          </div>
                        )
                      }
                    </div>
                  </li>

                  <li>
                    <Link to="/account_settings">
                      { displayName }
                    </Link>
                  </li>

                  <li>
                    <span onClick={() => setIsDropdownOpen(!showLanguageDropdown)} className={styles.languageTrigger}>
                      <span className={styles.lang}>
                        <span className={styles.name}>
                          {getLanguage(lang)}
                        </span>
                        <i className="fas fa-chevron-down" style={{ fontSize: '15px' }}/>
                      </span>
                      <LanguageDropdown />
                    </span>
                  </li>

                  <li>
                    <Link to="/">
                      <FormattedMessage id="sites" />
                    </Link>
                  </li>
                  <li>
                    <a onClick={logout}>
                      <FormattedMessage id="logout" />
                    </a>
                  </li>
                </ul>
              </nav>
            </header>
          )
        }
      </>

    )
  })
};

export default Header;
