import React, { useEffect, useState } from 'react';
import { useObserver } from 'mobx-react-lite';
import { useParams } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';

import { useStores, useError, useRedirect } from '../../hooks';
import { Label, Button, Spinner, Checkbox, Input, Error, Switch, Info, TimeInput } from '../elements';
import { ModalButton } from '../models';
import { openModal, getAccountInfo, handleError, controlOnErrors } from '../../utils';
import { ImagePreview } from '../partials';
import { Messages, translate } from '../I18n';

import {
  EditSiteModal,
  TransferSiteModal,
  DeleteSiteModal,
  DeleteSelectedDeviceModal,
  NewDeviceModal,
  LeaveSiteModal,
  ReplaceDeviceModal
} from '../modals';

import styles from '../../assets/styles/accountDetails.module.scss';

const SiteOverview = () => {
  const { siteStore, sharedUsersStore, global } = useStores(['siteStore', 'sharedUsersStore', 'global']);
  const { error, setError, clearError } = useError('');
  const { redirect } = useRedirect();
  const [performingCb, setPerformingCb] = useState(false);
  const [loading, setLoading] = useState(true);
  const { id } = useParams();

  useEffect(() => {
    const getContent = async () => {
      const { accountId } = getAccountInfo();

      const allowed = await siteStore.getById(id);

      if (!allowed) {
        redirect('/');
        return;
      }

      await sharedUsersStore.getAllSharedUsers();
      await siteStore.getSharedUsers(id, accountId);
      await siteStore.getDevicesFromSite(id);

      setLoading(false);
    }

    getContent();
  }, []);

  const openEditSiteSettings = () => {
    openModal('editSite');
  };

  const openClaimDevice = () => {
    openModal('newDevice');
  };

  const openDeleteModal = async () => {
    openModal('deleteSite');
  };

  const openLeaveModal = async () => {
    openModal('leaveSite');
  };

  const openTransferModal = () => {
    openModal('transferSite');
  };

  const updateSite = async () => {
    await siteStore.updateSite();
    Messages.success('updated_site');
  };

  const updateOfflineNotificationsSettings = async () => {
    await siteStore.updateOfflineNotificationsSettings();
    Messages.success('updated_offline_notifications');
  };

  const updateDealerAccess = async () => {
    await siteStore.updateDealerAccess();
    Messages.success('updated_dealer_access');
  };

  const connectToBoard = async device => {
    const { token } = await device.connect()
    window.location.href = `https://sso.bluecherry.io/proxy/device/login/${token}/${device.access_id}`;
  };

  const openDeleteDeviceModal = async device => {
    const { sharedUsers, selectedSite } = siteStore;
    const { devices } = selectedSite;

    if (sharedUsers.filter(su => !su.owner).length > ((devices.length - 1) * 5)) {
      Messages.error('delete_administrators_before_deleting_the_device');
      return
    }

    siteStore.selectedDevice = device;
    openModal('deleteSelectedDevice');
  };

  const openReplaceDeviceModal = async device => {
    siteStore.selectedDevice = device;
    openModal('replaceDeviceModal');
  };

  const resendInvite = async (ev, sharedUser) => {
    ev.stopPropagation();
    try {
      await siteStore.addSharedUser(sharedUser);
      Messages.success('invite_has_been_sent_to_email', { email: sharedUser.displayName });
    } catch (err) {
      const { status } = err;
      if (status !== undefined) {
        switch (status) {
          case 409:
            Messages.error('the_shared_user_is_already_an_admin_of_your_site');
            break;
          default:
            Messages.error('error_occured_in_the_server');
            break;
        }
      }
    }
  }

  const toggleSharedUser = async sharedUser => {
    if (sharedUser.performingCb || sharedUser.owner) {
      return;
    }

    const { email, shared } = sharedUser;

    sharedUser.shared = !shared;
    sharedUser.performingCb = true;

    if (!sharedUser.shared) {
      try {
        await siteStore.deleteSharedUser(sharedUser)
        Messages.success('deleted_shared_user', { email })
      } catch {
        Messages.error('failed_to_delete_shared_user', { email })
      }
    } else {
      try {
        await siteStore.addSharedUser(sharedUser);
        sharedUser.state = 'INVITED';
        Messages.success('invite_has_been_sent_to_email', { email })
      } catch (err) {
        Messages.error('failed_to_invite_user', { email })
      }
    }

    sharedUser.performingCb = false;
  };

  const addSharedUser = async () => {
    const { newSharedUser, sharedUsers } = siteStore;
    const { email } = newSharedUser;

    const match = sharedUsers.find(user => user.email === email && user.owner)
    if (match !== undefined) {
      setError('the_email_address_is_yours');
      return
    }

    try {
      clearError();
      await controlOnErrors(newSharedUser);
      setPerformingCb(true);

      await siteStore.addSharedUser(newSharedUser, true);
      Messages.success('invite_has_been_sent_to_email', { email: newSharedUser.email })
    } catch (err) {

      handleError(err, (status, body) => {
        if (status === 406 && body.code === 770) {
          setError('the_email_address_is_yours');
        } else if (status === 406) {
          setError('the_email_address_already_exists');
        } else if (status === 402) {
          setError('not_enough_credits_to_add_another_user');
        } else if (status === 426) {
          setError('not_enough_credits_to_add_another_user');
        } else if (body?.code === 180) {
          Messages.error('the_email_address_is_already_linked_to_this_site', { email: newSharedUser.email });
        }
      })
    }

    setPerformingCb(false);
  };

  const cancelTransfer = async () => {
    try {
      await siteStore.cancelTransfer();
      Messages.success('canceled_transfer_ownership');
    } catch {
      Messages.error('failed_to_cancel_transfer');
    }
  };

  const openDeleteSharedUser = (sharedUser) => {
    const deleteSharedUser = async () => {
      try {
        await siteStore.deleteSharedUser(sharedUser);

        siteStore.sharedUsers = siteStore.sharedUsers.filter(su => {
          return sharedUser.id !== su.id
        });


        Messages.success('deleted_shared_user', { email: sharedUser.email });
      } catch (err) {
        console.log(err)
        Messages.error('failed_to_delete_shared_user', { email: sharedUser.email });
      }
    }

    const title = translate('delete_shared_user_warning_title', { email: sharedUser.email });
    const message = translate('delete_shared_user_warning', { email: sharedUser.email });
    const buttons = [
      new ModalButton('delete', deleteSharedUser),
      new ModalButton('cancel'),
    ];

    global.displayWarningModal(title, message, buttons);
  }


  return useObserver(() => {
    const { sharedUsers: sharedUsersFromAccount } = sharedUsersStore;

    const { selectedSite, sharedUsers, newSharedUser } = siteStore;
    const { transferinfo, devices } = selectedSite;
    const { accountId } = getAccountInfo();
    const account = sharedUsers.find(user => user.owner === true) || {};
    const owner = accountId === account.account;

    const onlineDevices = devices.filter(device => device.dev_state);
    const emailsFromSiteUsers = sharedUsers.map(user => user.email.toLowerCase());

    // if (owner) {
    //   sharedUsersFromAccount.forEach(user => {
    //     if (!emailsFromSiteUsers.includes(user.email.toLowerCase())) {
    //       sharedUsers.push(user);
    //     }
    //   });
    // }

    return (
      <>
        <EditSiteModal />
        <NewDeviceModal />
        <TransferSiteModal />
        <LeaveSiteModal />
        <DeleteSiteModal />
        <DeleteSelectedDeviceModal />
        <ReplaceDeviceModal />


        <h1 className="title">
          <FormattedMessage id="site_management" values={{ name: selectedSite.name }} />
        </h1>

        {
          loading && (
            <Spinner />
          )
        }

        {
          !loading && (
            <>
              {
                owner && transferinfo !== '' && (
                  <section className={`${styles.container} ${styles.transferingContainer}`}>
                    <div className={styles.transfering}>
                      <div>
                        <i className="fas fa-exclamation-triangle" />
                        <FormattedMessage id="transfering_info" values={{ name: transferinfo.name }} />
                      </div>

                      <div onClick={cancelTransfer} className={styles.cancel}>
                        <FormattedMessage id="cancel" />
                      </div>
                    </div>
                  </section>
                )
              }

              <section className={styles.container}>
                <div className={styles.top}>
                  <h2>
                    <i className="fas fa-user" />
                    <FormattedMessage id="site_details" />
                  </h2>

                  {
                    owner && (
                      <Button text="edit" onClick={openEditSiteSettings} icon="fas fa-cog" />
                    )
                  }
                </div>

                <div>
                  <Label htmlFor="name" />
                  <span>
                    {selectedSite.name}
                  </span>
                </div>

                <Label htmlFor="image" />
                <ImagePreview entity={selectedSite} showButton={false} />
              </section>

              <section className={styles.container}>
                <div className={styles.top}>
                  <h2>
                    <i className="fas fa-plug" />
                    <FormattedMessage id="devices" />
                  </h2>

                  {
                    owner && (
                      <Button text="claim_device" onClick={openClaimDevice} icon="fas fa-plus" />
                    )
                  }
                </div>

                {
                  devices.length === 0 && (
                    <div className={`placeholder ${styles.placeholder}`}>
                      <FormattedMessage id="there_are_currently_no_devices_in_this_site" />
                    </div>
                  )
                }

                {
                  devices.length > 0 && (
                    <ul className={styles.devices}>
                      {
                        devices.map(device => {
                          return (
                            <li key={device.serialno} className={styles.device}>
                              <i className={device.dev_state ? styles.online : styles.offline} />
                              <div>
                                <span className={styles.name}>
                                  {device.name}
                                </span>
                                <span>{device.serialno}</span>
                              </div>

                              <div className={styles.actions}>
                                {
                                  device.dev_state && (
                                    <i className="fas fa-external-link-alt" onClick={() => connectToBoard(device)} />
                                  )
                                }

                                {
                                  owner && onlineDevices.length > 0 && (
                                    <i className="fas fa-sync" onClick={() => openReplaceDeviceModal(device)} />
                                  )
                                }


                                {
                                  owner && (
                                    <i className="fas fa-trash" onClick={() => openDeleteDeviceModal(device)} />
                                  )
                                }

                                {
                                  !device.dev_state && (
                                    <div></div>
                                  )
                                }
                              </div>

                            </li>
                          )
                        })
                      }

                    </ul>
                  )
                }

              </section>

              {
                owner && (
                  <>
                    <section className={styles.container}>
                      <div className={styles.top}>
                        <h2>
                          <i className="fas fa-bell" />
                          <FormattedMessage id="device_notifications" />
                        </h2>
                      </div>

                      <Info info="offline_notifications_info" />

                      <Switch
                        entity={selectedSite}
                        name="offline_notifications_enabled"
                        offLabel="notifications_disabled"
                        onLabel="notifications_enabled"
                        style={{ width: '100%', backgroundColor: '#f5f5f5' }}
                        afterChange={updateOfflineNotificationsSettings}
                      />
                    </section>
                    <section className={styles.container}>
                      <div className={styles.top}>
                        <h2>
                          <i className="fas fa-headset" />
                          <FormattedMessage id="dealer_access" />
                        </h2>
                      </div>

                      <Info info="dealer_access_info" />

                      {
                        selectedSite.dealer_access && (
                          <>
                            <div className="input">
                              <Label htmlFor="infinite_access" />
                              <Checkbox entity={selectedSite} name="infinite_access" afterChange={updateDealerAccess} />
                            </div>

                            {
                              selectedSite.dealer_access && !selectedSite.infinite_access && (
                                <div className="input">
                                  <Label htmlFor="dealer_access_duration" />
                                  <TimeInput entity={selectedSite} name="da_expires_at" afterChange={updateDealerAccess} />
                                </div>
                              )
                            }
                          </>
                        )
                      }

                      <Switch
                        entity={selectedSite}
                        name="dealer_access"
                        offLabel="dealer_access_disabled"
                        onLabel="dealer_access_enabled"
                        afterChange={updateDealerAccess}
                        style={{ width: '100%', backgroundColor: '#f5f5f5' }}
                      />
                    </section>
                  </>

                )
              }

              <section className={styles.container}>
                <div className={styles.top}>
                  <h2>
                    <i className="fas fa-users" />
                    <FormattedMessage id="administrators" />

                    <span className={styles.count}>
                      {sharedUsers.length}/{devices.length * 5}
                    </span>
                  </h2>
                </div>


                <Error error={error} activeErrors={newSharedUser.activeErrors} />

                <ul className={styles.sharedUsers}>
                  {
                    sharedUsers.map(sharedUser => {
                      return (
                        <li key={sharedUser.email} className={styles.siteSharedUser}>
                          <span className={styles.name}>
                            {
                              sharedUser.state === 'INVITED' && owner && (
                                <strong onClick={ev => resendInvite(ev, sharedUser)}>
                                  <FormattedMessage id="resend_invite" />
                                </strong>
                              )
                            }

                            {sharedUser.displayName}
                          </span>

                          {

                            (sharedUser.owner) && (
                              <div className={styles.actions} style={{ color: '#148fff' }}>
                                <FormattedMessage id="owner" />
                              </div>
                            )
                          }

                          {
                            owner && !sharedUser.owner && (
                              <div className={styles.actions}>
                                {
                                  sharedUser.performingCb && (
                                    <Spinner height={20} width={20} />
                                  )
                                }

                                <div className={styles.trashContainer} onClick={() => openDeleteSharedUser(sharedUser)}>
                                  <i className="fas fa-trash" />
                                </div>
                              </div>
                            )
                          }
                        </li>
                      )
                    })
                  }
                  {
                    owner && (
                      <li className={styles.addSharedUser}>
                        <Input entity={newSharedUser} name="email" placeholder="type_the_email_of_your_new_shared_user" />

                        {
                          !performingCb && (
                            <i className="fas fa-plus" onClick={addSharedUser} />
                          )
                        }

                        {
                          performingCb && (
                            <Spinner height={20} width={20} />
                          )
                        }
                      </li>
                    )
                  }
                </ul>
              </section>



              <section className={styles.container}>
                <div className={styles.top}>
                  <h2>
                    <i className="fas fa-cog" />
                    <FormattedMessage id="advanced_settings" />
                  </h2>
                </div>

                {
                  owner && (
                    <>
                      <Button text="transfer_ownership" onClick={openTransferModal} />
                      <Button
                        text="delete_site"
                        onClick={openDeleteModal}
                        disabled={devices.length !== 0}
                        disabled_message="deleting_a_site_is_not_possible_with_claimed_devices_to_it"
                        className={styles.error}
                      />
                    </>
                  )
                }

                {
                  !owner && (
                    <Button text="leave_site" onClick={openLeaveModal} className={styles.error} />
                  )
                }
              </section>

              <section className={styles.container}>
                <div className={styles.top}>
                  <h2>
                    <i className="fas fa-server" />
                    <FormattedMessage id="api_settings" />
                  </h2>
                </div>

                <div className={`btn ${styles.link}`}>
                  <a href={`https://${selectedSite.id.replace(/-/g, '')}.site.came.io`} target="_blank" rel="noreferrer">
                    {`https://${selectedSite.id.replace(/-/g, '')}.site.came.io`}
                  </a>
                </div>
              </section>
            </>
          )
        }

      </>

    )
  })
}

export default SiteOverview;
