export default (object, item, defaultValue = '', type) => {
  if (object !== undefined && item !== undefined) {
    if (object[item] !== undefined && object[item] !== '' && object[item] !== null) {
      try {
        if (Array.isArray(type)) {
          return object[item].map(data => new defaultValue(this, data));
        }
        return new defaultValue(this, object[item]);

      } catch (err) {
        return object[item];
      }
    }
  }

  try {
    if (Array.isArray(type)) {
      return []
    }

    return new defaultValue(this);

  } catch (err) {

    if (Array.isArray(type)) {
      return []
    }

    return defaultValue;
  }
};

