import { observable, decorate } from 'mobx';
import { Device } from '../components/models';
import { URL, doFetch } from '../utils';

const URLS = {
  GET_ALL_DEVICES: id => `${URL}/capi/sites/${id}/devices`,
};

class DeviceStore {
  constructor() {
    this.devices  = [];
    this.selectedDevice = new Device(this);
  };

  getAll = async id => {
    const devices = await doFetch(URLS.GET_ALL_DEVICES(id), 'GET')
    this.devices = devices.map(device => new Device(this, device));

    return this.devices;
  };
}

decorate(DeviceStore, {
  devices: observable,
  selectedDevice: observable
});

export default new DeviceStore();
