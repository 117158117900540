import React, { Component } from 'react';
import uuidv4 from 'uuid/v4';
import { inject, observer } from 'mobx-react';
import { FormattedMessage } from 'react-intl';

import { Label, Button, Header, Headers } from '../elements';
import { openModal, closeModal, getAccountInfo, controlOnErrors } from '../../utils';
import { SiteForm } from '../forms';
import { Messages, translate } from '../I18n';
import { ImagePreview } from '../partials';
import { Screen, ModalButton, Column } from '../models';

import { TransferModal, InviteUserModal, NewDeviceModal, DeleteModal } from '../modals';

const Overview = observer(({ selectedSite, update, displayWarningDeleteSite, devices }) => (
  <div className="screen">
    <div className="form">
      <SiteForm entity={selectedSite} context={this} />
      <Label htmlFor="image" />
      <ImagePreview entity={selectedSite} showButton={false} />
    </div>

    <div className="buttons">
      <div className="btn" role="button" onClick={() => openModal('cropper')}>
        <FormattedMessage id="change_image" />
      </div>
      <div className="btn" role="button" onClick={update}>
        <FormattedMessage id="update" />
      </div>

      {
        devices.length !== 0 && (
          <div className="btn error disabled">
            <FormattedMessage id="delete" />
          </div>
        )
      }

      {
        devices.length === 0 && (
          <div className="btn error" role="button" onClick={displayWarningDeleteSite}>
            <FormattedMessage id="delete" />
          </div>
        )
      }
      
    </div>
  </div>
));


const AsideScreen = ({ screen, changeScreen, screenData }) => (
  <li
    className={`collection-item ${screenData.translation === screen ? 'active' : ''}`}
    onClick={() => changeScreen(screenData.translation)}
  >
    <i className={`fa ${screenData.icon}`} aria-hidden="true" />
    <FormattedMessage id={screenData.translation} />
  </li>
);

const Screens = ({ screens, changeScreen, screen }) => (
  screens.map(screenData => (
    <AsideScreen
      screen={screen}
      changeScreen={changeScreen}
      key={uuidv4()}
      screenData={screenData}
    />
  ))
);

const SharedUsers = ({ sharedUsers, deleteSharedUser }) => (
  sharedUsers.map(user => (
    <li key={user.id} className="collection-item">
      <div>{ user.getDisplayName }</div>
      <div>{ user.owner ? 'true' : 'false' }</div>
      {
        !user.owner && (
          <i className="fa fa-trash-o" aria-hidden="true" onClick={() => deleteSharedUser(user)} />
        )
      }
    </li>
  ))
);

const Administrators = ({ shouldShowTransferButton, sharedUsers, deleteSharedUser, openTransferModal, openInviteModal }) => (
  <div className="screen">
    <div className="form">
      {
        sharedUsers.length === 0 && (
          <div className="placeholder">
            <FormattedMessage id="there_are_currently_no_administrators_linked_to_this_site" />
          </div>
        )
      }

      {
        sharedUsers.length > 0 && (
          <ul className="collection sharedUsers customCollapsible" data-collapsible="accordion">
            <li className="collection-item">
              <Header header="name" />
              <Header header="owner" />
            </li>


            <SharedUsers 
              sharedUsers={sharedUsers}
              deleteSharedUser={deleteSharedUser}
            />
          </ul>
        )
      }
    </div>

    <div className="buttons">
      {
        shouldShowTransferButton && (
          <Button onClick={openTransferModal} text="transfer_ownership" />
        )
      }
      <Button onClick={openInviteModal} text="invite_user" />
    </div>
  </div>
);


@inject('deviceStore', 'siteStore', 'global')
@observer
export default class DeviceOverview extends Component {
  constructor() {
    super();

    this.state = {
      screen: 'overview',
      screens: [
        new Screen('overview', 'fa-user'),
        new Screen('devices', 'fa-paperclip'),
        new Screen('administrators', 'fa-key'),
      ],
      columns: [
        new Column(this, 'state', 'dev_state'),
        new Column(this, 'serialno', 'serialno'),
        new Column(this, 'name', 'name'),
      ],
      update: Date.now()
    };
  }

  componentDidMount = () => {
    const { deviceStore, siteStore, match } = this.props;
    const { id, screen } = match.params;

    try {
      siteStore.getById(id);
    } catch (err) {
      console.log(err)
    }
    deviceStore.getAll(id);
    siteStore.getSharedUsers(id);

    if (screen !== undefined) {
      this.setState({ screen });
    }

    // todo refactor
  };

  searchWithFilters = () => {
    this.setState({ update: Date.now()});
  }

  componentDidUpdate = prevProps => {
    const { screen } = this.props.match.params;
    if (screen !== prevProps.match.params.screen) {
      this.setState({ screen });
    }
  };

  updateSite = async () => {
    const { siteStore } = this.props;

    await siteStore.updateSite()
    Messages.success(translate('updated_site'));
  };

  openInviteModal = () => {
    openModal('inviteUser');
  };

  deleteSite = async site => {
    const { name } = site;

    try {
      await this.props.siteStore.deleteSite(site);
      Messages.success('deleted_site', { name });
      setTimeout(() => {
        this.props.history.push('/');
      }, 500);
    } catch (err) {
      console.log(err);
      Messages.error('failed_to_delete_site', { name });
    }
  };


  displayWarningDeleteSite = site => {
    const title = translate('delete_site_title', { name: site.name });
    const message = translate('delete_site_warning', { name: site.name });
    const buttons = [
      new ModalButton(translate('delete_site'), () => this.deleteSite(site)),
      new ModalButton('cancel'),
    ];

    this.props.global.displayWarningModal(title, message, buttons);
  };

  changeScreen = screen => {
    const { match, history } = this.props;
    const { id } = match.params;
    history.push(`/site_overview/${id}/${screen}`);
  };

  inviteUser = async () => {
    const { siteStore, match } = this.props;
    const { id } = match.params;
    const { newAdmin } = siteStore;

    try {
      await newAdmin.inviteUser(id)
      Messages.succes('invited_user');
      closeModal('inviteUser');
    } catch (err) {
      console.log(err);
    }
  };

  claimDevice = async () => {
    const { siteStore, deviceStore, match } = this.props;
    const { id } = match.params;
    const { devices } = deviceStore;
    const { newDevice, selectedSite } = siteStore;

    try {
      await newDevice.claim(selectedSite, devices)
      Messages.success('claimed_device');
      deviceStore.getAll(id);
      siteStore.resetDevice();
      closeModal('newDevice');
    } catch (err) {
      console.log(err, 'error hierin');
      Messages.error('failed_to_claim_device');
    }
  };

  openCropper = () => {
    openModal('cropper');
  };

  deleteDevice = () => {
    const { deviceStore, siteStore } = this.props;
    const { selectedDevice } = deviceStore;

    return deviceStore.deleteDevice(selectedDevice, siteStore.selectedSite)
      .then(() => {
        Messages.succes('deleted_device');
        closeModal('deleteDevice');
      })
      .catch(err => {
        console.log(err);
        Messages.error('failed_to_delete_device');
      });
  };

  openDeleteDevice = device => {
    const { deviceStore } = this.props;
    deviceStore.selectedDevice = device;
    openModal('deleteDevice');
  };

  openTransferModal = () => {
    openModal('transferModal');
  };

  deleteSharedUser = user => {
    const { siteStore } = this.props;

    siteStore.deleteSharedUser(user)
      .then(() => Messages.succes('deleted_user'))
      .catch(err => {
        console.log(err);
        Messages.error('failed_to_delete_user');
      });
  };

  transferOwnership = () => {
    const { siteStore } = this.props;
    const { transferOwnership } = siteStore;

    const transfer = () => {
      return transferOwnership()
        .then(() => {
          Messages.succes('transfered_ownership');
          closeModal('transferModal');
        })
        .catch(err => {
          console.log(err);
          Messages.error('failed_to_transfer_ownership');
        });
    };



    // controlOnErrors(email)
    //   .then(transfer);
  };

  connectToDevice = device => {
    device.connect()
      .then(({ token }) => {
        window.location.href = `https://sso.bluecherry.io/proxy/device/login/${token}/${device.access_id}`;
      })
      .catch(err => console.log(err));
  };

  render() {
    const { screens, screen } = this.state;
    const { deviceStore, siteStore } = this.props;
    const { selectedSite, sharedUsers, newDevice, newAdmin } = siteStore;
    const { devices } = deviceStore;
    const { accountId } = getAccountInfo();
    const { id } = sharedUsers.find(user => user.owner === true) || {};
    const shouldShowTransferButton = accountId === id;

    const allDevicesOnline = !devices.map(device => device.dev_state).includes(false);

    const handleLowerCase = (column) => {
      return (typeof column === 'string')
        ? column.toLowerCase()
        : column;
    };

    let devicesToRender = devices;

    if (this.state.update) {
      this.state.columns.filter(column => column.status !== 2).forEach(column => {
        devicesToRender = devicesToRender.sort((left, right) => {
          const leftSide = handleLowerCase(left[column.dbColName]);
          const rightSide = handleLowerCase(right[column.dbColName]);

          if (column.status  === 0) {
            return leftSide === rightSide ? 0 : leftSide > rightSide ? -1 : 1;
          }

          return leftSide === rightSide ? 0 : leftSide < rightSide ? -1 : 1;
        });
      });
    }

    return (
      <div>
        <NewDeviceModal
          entity={newDevice}
          claimDevice={this.claimDevice}
        />

        <InviteUserModal
          entity={newAdmin}
          invite={this.inviteUser}
        />

        <TransferModal
          entity={account}
          transfer={this.transferOwnership}
          site={selectedSite}
        />

        <DeleteModal 
          deleteDevice={this.deleteDevice}
        />

        <h1>
          <FormattedMessage id="site_management" values={{ name: selectedSite.name }} />
        </h1>


        <div className="management">
          <div className="aside">
            <ul className="custom-collection">
              <Screens screens={screens} changeScreen={this.changeScreen} screen={screen} />
            </ul>
          </div>

          {
            screen === 'overview' && (
              <Overview
                selectedSite={selectedSite}
                update={this.updateSite}
                displayWarningDeleteSite={() => this.displayWarningDeleteSite(selectedSite)}
                devices={devices}
              />
            )
          }

          {
            screen === 'devices' && (
              <div className="screen">
                <div className="form">
                  {
                    !allDevicesOnline && (
                      <div className="warning">Make sure all devices are online before you want to claim or delete a device</div>
                    )
                  }
                  {
                    devices.length === 0 && (
                      <div className="placeholder">
                        <div className="title">
                          There are currently no devices linked to this site, Click on the "claim device" button below to start the claim process.
                        </div>
                      </div>
                    )

                  }
                  {
                    devices.length > 0 && (
                      <ul className="collection customCollapsible deviceList" data-collapsible="accordion">
                        <li className="collection-item">
                          <Headers columns={this.state.columns} />
                        </li>

                        {
                          devicesToRender.map(device => {
                            return (
                              <li key={device.serialno} className="collection-item">
                                <div className={device.dev_state ? 'unused-code' : 'used-code'} />
                                <div>{device.serialno}</div>
                                <div>{device.name !== '' ? device.name : device.serialno}</div>

                                {
                                  allDevicesOnline && (
                                    <i className="fa fa-trash-o" aria-hidden="true" onClick={() => this.openDeleteDevice(device)} />
                                  )
                                }

                                {
                                  allDevicesOnline && (
                                    <i className="fa fa-external-link" aria-hidden="true" onClick={() => this.connectToDevice(device)} />
                                  )
                                }
                              </li>
                            );
                          })
                        }


                      </ul>
                    )
                  }

                </div>
                <div className="buttons">
                  <Button text="claim_device" onClick={() => openModal('newDevice')} disabled={!allDevicesOnline}/>
                </div>
              </div>
            )
          }

          {
            screen === 'administrators' && (
              <Administrators 
                sharedUsers={sharedUsers}
                deleteSharedUser={this.deleteSharedUser}
                openTransferModal={this.openTransferModal}
                openInviteModal={this.openInviteModal}
                shouldShowTransferButton={shouldShowTransferButton}
              />
            )
          }
          
        </div>
      </div>
    );
  }
}
