import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useObserver } from 'mobx-react-lite';
import { Modal, Button } from '../elements';
import { closeModal } from '../../utils';
import { useStores } from '../../hooks';
import { AccountForm } from '../forms';
import { Messages } from '../I18n';

const EditAccountModal = () => {
  const { settingsStore, global } = useStores(['settingsStore', 'global']);

  const close = () => {
    closeModal('editAccount');
  };

  const cancel = () => {
    settingsStore.account.cancel();
    close();
  };


  const saveAccountSettings = async () => {
    await settingsStore.saveSettings()
    global.refreshAccountName();
    Messages.success('saved_settings');
    close();
  };

  return useObserver(() => {
    const { account } = settingsStore;

    return (
      <Modal id="editAccount">
        <div className="modal-header">
          <h1>
            <FormattedMessage id="edit_account_details"/>
          </h1>
          <i className="fas fa-times" onClick={close} />
        </div>
        <div className="modal-content">
          <AccountForm entity={account} />
        </div>
        <div className="modal-footer">
          <Button text="save" onClick={saveAccountSettings} />
          <Button text="cancel" onClick={cancel} />
        </div>
      </Modal> 
    )
  })
};

export default EditAccountModal;
