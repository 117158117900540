import React, { useEffect } from 'react';
import { useObserver } from 'mobx-react-lite';
import {useStore} from '../../hooks';
import {translate} from '../I18n';

const SearchForm = ({ display = true, className = '' }) => {
  const global = useStore('global');
  let searchInput = '';

  useEffect(() => {
    if (display) {
      searchInput.focus();
      searchInput.click();
    }
  }, [display]);

  const onChange = ev => {
    global.searchterm = ev.target.value;
  };

  return useObserver(() => {
    const { searchterm } = global;
    return (
      display && (
        <div className={`searchform ${className}`}>
          <div className="special-input input">
            <input type="text" value={searchterm} onChange={onChange} placeholder={translate('type_to_search')} ref={input => { searchInput = input }} />

            {
              searchterm === '' && (
                <i className="fas fa-search" />
              )
            }

            {
              searchterm !== '' && (
                <i className="fas fa-times" onClick={() => global.searchterm = ''} />
              )
            }
          </div>
        </div>
      )
    )
  })
}

export default SearchForm;
