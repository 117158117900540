import React from 'react';
import { FormattedMessage } from 'react-intl';

const Header = ({header, hideOnMobile = false}) => (
  <span className={hideOnMobile ? 'hideOnMobile' : ''}><FormattedMessage id={header} /></span>
);

export default Header;


