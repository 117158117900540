import { observable, decorate } from 'mobx';
import { Account, NewPassword } from '../components/models';
import { USER_URL, doFetch, controlOnErrors } from '../utils';

const URLS = {
  GET_USER_INFO: accountId => `${USER_URL}/api/user/account/${accountId}`,
  SAVE_SETTINGS: `${USER_URL}/api/user/account/accountId`,
  RESET_PASSWORD: id => `${USER_URL}/api/user/${id}/changepassword`,
};

class SettingsStore {
  constructor() {
    this.account = new Account();
    this.newPassword = new NewPassword();
  };

  saveSettings = async () => {
    await doFetch(URLS.SAVE_SETTINGS, 'PUT', this.account.toJS());
  };

  fetchSettings = async () => {
    const account = await doFetch(URLS.GET_USER_INFO(), 'GET')
    this.account = new Account(this, account);
  };

  changePassword = async id => {
    await controlOnErrors(this.newPassword);
    await doFetch(URLS.RESET_PASSWORD(id), 'PUT', this.newPassword.toJS());
    return this.newPassword = new NewPassword();
  };
}

decorate(SettingsStore, {
  account: observable,
  newPassword: observable,
});

export default new SettingsStore();
