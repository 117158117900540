import React, { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { Button, Spinner } from '../elements';
import { doFetch, getAccountInfo } from '../../utils';
import { Messages } from '../I18n';

import cross_circle from '../../assets/images/cross.svg';
import check_circle from '../../assets/images/check-circle.svg';
import {useObserver} from 'mobx-react-lite';
import {useRedirect} from '../../hooks';

const AcceptNotification = ({ match }) => {
  const { redirect } = useRedirect();
  const [ status, setStatus] = useState(false)
  const [ activating, setActivating ] = useState(true)

  useEffect(() => {
    const getState = async () => {
      const { accountId } = getAccountInfo();
      const { id } = match.params;

      try {
        await doFetch(`/capi/notification/accept/action/${id}`, 'POST');
        Messages.success('accepted_notification');
        setActivating(false);
        setStatus(true);
      } catch {
        setStatus(false);
        setActivating(false);
        Messages.error('failed_to_accept_the_notification');
      }
    };

    getState();
  }, []);

  const redirectToHome = () => {
    redirect('/')
  };

  return useObserver(() => {
    return (
      <div>
        <h1 className="title">
          <FormattedMessage id="accepting_notification" />
        </h1>

        {
          activating && (
            <Spinner />
          )
        }

        {
          !activating && !status && (
            <div>
              <img src={cross_circle} className="checked_image" alt="checked circle" width="11%" />
              <h2 style={{ fontFamily: 'raleway', color: '#444', fontSize: '2.3rem', textAlign: 'center' }}>
                <FormattedMessage id="failed_to_accept_the_notification" />
              </h2>
            </div>
          )
        }

        {
          !activating && status && (
            <div>
              <img src={check_circle} className="checked_image" alt="checked circle" width="11%" />
              <h2 style={{ fontFamily: 'raleway', color: '#444', fontSize: '2.3rem', textAlign: 'center' }}>
                <FormattedMessage id="accepted_the_notification" />
              </h2>
            </div>
          )
        }

        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '40px' }}>
          <Button text="go_to_the_overview_of_your_sites" onClick={redirectToHome}/>
        </div>
      </div>
    )
  });
};

export default AcceptNotification
