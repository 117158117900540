import React, { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useObserver } from 'mobx-react-lite';

import { Messages } from '../I18n';
import { Modal, Checkbox, Spinner } from '../elements';
import { useStore } from '../../hooks';
import { closeModal } from '../../utils';
import styles from '../../assets/styles/notifications.module.scss';

const MultiOptions = ({ options, className, icon}) => {
  const [overlayActive, setOverlayActive] = useState(false);

  const optionKeys = Object.keys(options);

  const performCb = option => {
    if (options[option] !== undefined) {
      options[option]();
    }
  };

  return (
    <>
      {
        overlayActive && (
          <div className={styles.overlay} onClick={() => setOverlayActive(false)}/>
        )
      }

      <div className={className} onClick={() => setOverlayActive(!overlayActive)}>
        <i className={icon}/>

        {
          overlayActive && (
            <ul className={styles.options}>
              {
                optionKeys.map(option => (
                  <li key={option} onClick={() => performCb(option)}>
                    <FormattedMessage id={option} />
                  </li>
                ))
              }
            </ul>
          )
        }
      </div>  
    </>
    
  )
};

const NotificationModal = () => {
  const notificationStore = useStore('notificationStore');
  const siteStore = useStore('siteStore');
  const { lang } = useStore('global');
  const [searchTerm, setSearchTerm ] = useState('');
  const [selectionMode, setSelectionMode] = useState(false);

  useEffect(() => {
    notificationStore.getAllNotifications()
  }, []);

  const onChange = ev => {
    setSearchTerm(ev.target.value);
  };

  const close = () => {
    closeModal('notificationModal');
  };

  const closeWhenNoMessagesAreLeft = () => {
    const { notifications } = notificationStore;

    if (notifications.length === 0) {
      close();
    }
  };

  const acceptNotification = async notification => {
    try {
      notification.performingCb = true;
      await notificationStore.acceptNotification(notification)
      await siteStore.getAll();
      await notificationStore.getAllNotifications()
      Messages.success('accepted_notification');
      closeWhenNoMessagesAreLeft();
    } catch (err) {
      console.log(err);
      const { status } = err;
      switch (status) {
        case 402:
          Messages.error('this_account_does_not_have_enough_device_credits_to_accept_this_transfer');
          break;
        default:
          Messages.error('error_accepted_notification');
      }
    }

    notification.performingCb = false;
  };

  const declineNotification = async notification => {
    try {
      notification.performingCb = true;
      await notificationStore.declineNotification(notification)
      Messages.success('declined_notification');
      await notificationStore.getAllNotifications()
      closeWhenNoMessagesAreLeft();
    } catch (err) {
      Messages.error('error_declined_notification');
    }

    notification.performingCb = false;
  };

  const deleteNotification = async notification => {
    try {
      await notificationStore.deleteNotification(notification);
      Messages.success('deleted_notification');
    } catch {
      Messages.error('failed_to_delete_notification');
    }
  };

  const selectNotificiation = notification => {
    setSelectionMode(true);
    notification.selected = true;
  };

  const changeState = (notification, state) => {
    if (state !== notification.state) {
      notificationStore.changeState(notification, state);
    }
  };

  const activateSelectionMode = () => {
    setSelectionMode(true);
  };

  const deactivateSelectionMode = () => {
    setSelectionMode(false);
  };

  const getActiveNotifications = () => {
    const { notifications } = notificationStore;

    return notifications.filter(notification => {
      return JSON.stringify(notification).toLowerCase().includes(searchTerm.toLowerCase());
    });
  };

  const selectAll = () => {
    setSelectionMode(true);
    const matchingNotifications = getActiveNotifications();

    matchingNotifications.forEach(notification => {
      notification.selected = true;
    });
  };

  const unSelectAll = () => {
    const matchingNotifications = getActiveNotifications();

    matchingNotifications.forEach(notification => {
      notification.selected = false;
    });
  };

  const markSelectedAsRead = async () => {
    await notificationStore.markSelectedAsRead();
    setSelectionMode(false);
  };

  const markSelectedAsUnread = async () => {
    await notificationStore.markSelectedAsUnread();
    setSelectionMode(false);
  };

  const markSelectedAsDeleted = async () => {
    try {
      await notificationStore.deleteSelected();
      setSelectionMode(false);
      Messages.success('deleted_notification');
    } catch {
      Messages.error('failed_to_delete_notifications');
    }
  };

  const toggleNotificationSelection = notification => {
    notification.selected = !notification.selected;
  };

  const getActiveOptions = () => {
    const { notifications } = notificationStore;
    const selectedNotifications = notifications.filter(notification => notification.selected);

    const baseSelectionOptions = {
      select_all: selectAll,
      stop_selection_mode: deactivateSelectionMode
    };

    const extraSelectionOptions = {
      unselect_all: unSelectAll,
      mark_selected_as_read: markSelectedAsRead,
      mark_selected_as_unread: markSelectedAsUnread,
      delete_selected: markSelectedAsDeleted,
    };


    if (selectionMode && selectedNotifications.length > 0) {
      return {...baseSelectionOptions, ...extraSelectionOptions}
    } else if (selectionMode) {
      return baseSelectionOptions
    }

    return {
      activate_selection_mode: activateSelectionMode,
      select_all: selectAll,
    }
  };

  const onClose = () => {
    setSelectionMode(false);
  };

  return useObserver(() => {
    const notificationsToRender = getActiveNotifications();
    const options = getActiveOptions();

    return (
      <Modal id="notificationModal" onClose={onClose}>
        <div className="modal-header">
          <h1>
            <FormattedMessage id="my_messages" />
          </h1>

          <i className="fas fa-times" onClick={close}/>
        </div>

        <div className="modal-content">
          <div className={styles.searchContainer}>
            <div className={styles.search}>
              <input type="text" value={searchTerm} onChange={onChange} placeholder="Type to search..." />
              <i className="fa fa-search" aria-hidden="true" />
            </div> 
            <MultiOptions 
              className={styles.multiOptions}
              icon="fas fa-ellipsis-h"
              options={options}
            />
            
          </div>
          {
            notificationsToRender.length === 0 && (
              <div className="placeholder">
                <h1>
                  <FormattedMessage id="there_are_currently_no_active_notifications" />
                </h1>
              </div>
            )
          }

          {
            notificationsToRender.length > 0 && (
              <ul className={styles.notifications}>
                {
                  notificationsToRender.map(notification => {
                    const extraOptions = notification.state === 'NEW'  || notification.state == 'MARKED_NEW'
                      ? { mark_as_read: () => changeState(notification, 'VIEWED') }
                      : { mark_as_unread: () => changeState(notification, 'MARKED_NEW') }

                    return (
                      <li 
                        className={styles.notification} 
                        key={notification.id} 
                        onClick={() => selectionMode && toggleNotificationSelection(notification)}
                        style={selectionMode ? {cursor: 'pointer'} : {}}
                      >
                        {
                          selectionMode && (
                            <div>
                              <Checkbox 
                                entity={notification} 
                                name="selected" 
                                style="round" 
                                unSelectedColor="#fff"
                                disabled
                              />
                            </div>
                          )
                        }

                        {
                          !selectionMode && (
                            <>
                              {
                                notification.type === 'ACTIVE' && (
                                  <i className={`fas fa-exclamation ${styles.exclamation}`} />
                                )
                              }
                              <i 
                                className={`fas ${(notification.state === 'NEW' || notification.state === 'MARKED_NEW') ? 'fa-envelope' : 'fa-envelope-open'}`} 
                                onClick={() => changeState(notification)}
                              />
                            </>
                          )
                        }

                        <div className={styles.information} onClick={() => changeState(notification)}>
                          <div className={styles.type}>
                            <strong>
                              <FormattedMessage id={notification.actionType} />
                            </strong>

                            <span className={styles.date}>
                              {
                                notification.displayDate
                              }
                            </span>
                          </div>

                          <span className={styles.message}>
                            {notification.getMessage(lang)}
                          </span>
                        </div>

                        {
                          !selectionMode && notification.performingCb && (
                            <div className={styles.actions}>
                              <Spinner height={30} width={30}/>
                            </div>
                          )
                        }

                        {
                          !selectionMode && !notification.performingCb && (
                            <div className={styles.actions}>
                              {
                                notification.type === 'ACTIVE' && (
                                  <>
                                    <div className={styles.accept} onClick={() => acceptNotification(notification)}>
                                      <i className="fas fa-check" />
                                    </div>
                                    <div className={styles.decline} onClick={() => declineNotification(notification)}>
                                      <i className="fas fa-times" />
                                    </div>      
                                  </>
                                )
                              }

                              {
                                notification.type !== 'ACTIVE' && (
                                  <>
                                    <div className={styles.spacer}></div>
                                    <div className={styles.spacer}></div>
                                  </>
                                )
                              }

                              <MultiOptions 
                                className={styles.multiOptions}
                                icon="fas fa-ellipsis-h"
                                options={{
                                  delete: () => deleteNotification(notification),
                                  select: () => selectNotificiation(notification),
                                  ...extraOptions
                                }}
                              />

                            </div>
                          )
                        }


                      </li>
                    );
                  })
                }
              </ul>
            )
          }

        </div>
      </Modal>
    )
  })
}

export default NotificationModal;
