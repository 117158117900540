import React, { Component, Fragment } from 'react';
import { observer, inject } from 'mobx-react';
import { Input, Label } from '../elements';

@inject('deviceStore')
@observer
export default class DeviceForm extends Component {
  render() {
    const { entity } = this.props;
    const { site, dev_id, type_id } = entity;

    return (
      <Fragment>
        <div className="input">
          <Label htmlFor="site" />
          <Input context={this} id="site" type="text" name="site" value={site} />
        </div>

        <div className="input">
          <Label htmlFor="dev_id" />
          <Input context={this} id="dev_id" type="text" name="dev_id" value={dev_id} />
        </div>

        <div className="input">
          <Label htmlFor="type_id" />
          <Input context={this} id="type_id" type="text" name="type_id" value={type_id} />
        </div>
      </Fragment>

    );
  }
}
