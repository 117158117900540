import { observable, decorate } from 'mobx';
import { doFetch, URL } from '../utils';
import { Notification } from '../components/models';

const URLS = {
  GET_ALL_NOTIFICATIONS: `${URL}/api/user/notification/all`,
  ACCEPT_NOTIFICATION: id => `${URL}/capi/notification/accept/action/${id}`,
  DECLINE_NOTIFICATION: id => `${URL}/capi/notification/decline/action/${id}`,
  CHANGE_STATE: id => `${URL}/api/user/notification/${id}`,
  DELETE_NOTIIFCATION: id => `${URL}/capi/notification/${id}`,
  CHANGE_LIST_STATE: `${URL}/api/user/notification/list`,
  DELETE_MULTIPLE_NOTIFICATIONS: `${URL}/capi/notification/list`
};

class NotificationStore {
  constructor() {
    this.notifications = [];
    this.fetchedNotifcations = false;

    this.notificationTypes = {
      DEVICE_SHARE: 'DEVICE_SHARE',
      DEVICE_NOTIFICATION: 'DEVICE_NOTIFICATION',
      TRANSFER_OWNERSHIP: 'TRANSFER_OWNERSHIP',
      LICENSE_THIRTY_DAYS_LEFT: 'LICENSE_THIRTY_DAYS_LEFT',
      LICENSE_FOURTEEN_DAYS_LEFT: 'LICENSE_FOURTEEN_DAYS_LEFT',
      LICENSE_EXPIRING: 'LICENSE_EXPIRING',
      LICENSE_OVERDUE: 'LICENSE_OVERDUE',
      DEVICE_ONLINE: 'DEVICE_ONLINE',
      DEVICE_OFFLINE: 'DEVICE_OFFLINE',
    }

    this.notificationTimeout;
    this.poleNots();
  };

  poleNots = async () => {
    if (this.notificationTimeout !== undefined) {
      clearTimeout(this.notificationTimeout);
    }

    this.notificationTimeout = setTimeout(async () => {
      await this.getAllNotifications();
      this.poleNots();
    }, 5000);
  };


  changeListState = async state => {
    const selectedNotifications = this.notifications.filter(notifaction => notifaction.selected);
    const notificationIds = selectedNotifications.map(notification => notification.id);

    await doFetch(URLS.CHANGE_LIST_STATE, 'PUT', {
      state,
      notifications: notificationIds
    });

    selectedNotifications.forEach(notifaction => {
      notifaction.state = state;
    });
  };

  deleteSelected = async () => {
    const selectedNotifications = this.notifications.filter(notifaction => notifaction.selected);
    const notificationIds = selectedNotifications.map(notification => notification.id);

    await doFetch(URLS.DELETE_MULTIPLE_NOTIFICATIONS, 'DELETE', {
      notifications: notificationIds
    });

    this.notifications = this.notifications.filter(notification => !notificationIds.includes(notification.id));
  };

  markSelectedAsRead = () => {
    this.changeListState('VIEWED');
  };

  markSelectedAsUnread = () => {
    this.changeListState('MARKED_NEW');
  };

  declineNotification = async ({ id }) => {
    await doFetch(URLS.DECLINE_NOTIFICATION(id), 'POST');
    this.notifications = this.notifications.filter(notification => notification.id !== id);
  };

  deleteNotification = async ({id}) => {
    await doFetch(URLS.DELETE_NOTIIFCATION(id), 'DELETE');
    this.notifications = this.notifications.filter(notification => notification.id !== id);
  };

  acceptNotification = async ({ id }) => {
    await doFetch(URLS.ACCEPT_NOTIFICATION(id), 'POST');
    this.notifications = this.notifications.filter(notification => notification.id !== id);
  };

  changeState = async (notification, state = 'VIEWED') => {
    const { id } = notification;

    const obj = {
      state,
    };

    await doFetch(URLS.CHANGE_STATE(id), 'PUT', obj);
    notification.state = state;
  };


  getAllNotifications = async () => {
    this.fetchedNotifcations = true;

    const notifications = await doFetch(URLS.GET_ALL_NOTIFICATIONS);

    const currentIds = this.notifications.map(not => not.id);
    const newIds = notifications.map(not => not.id);


    // store this in a new array so we only have to update the observable once
    const updatedArr = this.notifications.filter(notification => newIds.includes(notification.id));

    notifications.reduce((notArr, notification) => {
      if (!currentIds.includes(notification.id)) {
        notArr.push(new Notification(notification));
      }

      return notArr
    }, updatedArr);


    this.notifications = updatedArr.sort((a, b) => {
      return (parseInt(a.date) > parseInt(b.date)) ? -1 : ((parseInt(a.date) < parseInt(b.date)) ? 1 : 0)
    });
  };
}

decorate(NotificationStore, {
  notifications: observable,
  fetchedNotifcations: observable,
});

export default new NotificationStore();
