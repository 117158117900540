import { computed } from 'mobx';

export default class Model {
  constructor(context, data) {
    this.context = context;
    this.originalData = data;
  };

  save = () => {
    this.originalData = this.toJS();
  };

  cancel = () => {
    this.init(this.originalData);
    this.resetErrors();
  }

  reset = () => {
    this.init(this.originalData);
    this.resetErrors();
  };


  resetErrors = () => {
    Object.keys(this)
      .filter(key => key.includes('error'))
      .forEach(key => {
        this[key] = false
      })
  }


  @computed
  get activeErrors() {
    const errors = Object.keys(this).filter(key => key.includes('error') && this[key].error);
    return errors.map(error => this[error]);
  };
};
