import React, { useEffect } from 'react';
import { useObserver } from 'mobx-react-lite';
import { FormattedMessage } from 'react-intl';

import { useStores } from '../../hooks';
import { Label, Button } from '../elements';
import { openModal } from '../../utils';
import {
  EditPasswordModal,
  EditAccountModal,
  EditSharedUsersModal,
} from '../modals';

import styles from '../../assets/styles/accountDetails.module.scss';
import { translate } from '../I18n';

const AccountDetails = () => {
  const { settingsStore, sharedUsersStore, global } = useStores(['settingsStore', 'sharedUsersStore', 'global']);

  useEffect(() => {
    settingsStore.fetchSettings();
    sharedUsersStore.getAllSharedUsers();
  }, []);

  const openEditPasswordModal = () => {
    openModal('editPassword');
  };

  const openEditAccountSettings = () => {
    openModal('editAccount');
  };

  const openEditSharedUsersSettings = () => {
    openModal('editSharedUsers');
  };

  return useObserver(() => {
    const { account } = settingsStore;
    const { sharedUsers } = sharedUsersStore;

    return (
      <>
        <EditPasswordModal />
        <EditAccountModal />
        <EditSharedUsersModal />

        <h1 className="title">
          <FormattedMessage id="account_settings" />
        </h1>

        <section className={styles.container}>
          <div className={styles.top}>
            <h2>
              <i className="fas fa-user" />
              <FormattedMessage id="account_details" />
            </h2>

            <Button text="edit" onClick={openEditAccountSettings} icon="fas fa-cog" />
          </div>

          <div>
            <Label htmlFor="name" />
            <span>
              {account.displayName}
            </span>
          </div>

          <div>
            <Label htmlFor="email" />
            <span>
              {account.email}
            </span>
          </div>

          <div>
            <Label htmlFor="mobile" />
            <span>
              {account.mobile}
            </span>
          </div>

          <div>
            <Label htmlFor="country" />
            <span>
              {global.getLocale(account.locale)}
            </span>
          </div>

          <div className={account.imp_enabled ? styles.enabled : styles.disabled}>
            <FormattedMessage id="impersonation_is_currently_status" values={{
              status: account.imp_enabled ? translate('allowed') : translate('not_allowed')
            }} />
          </div>
        </section>

        <section className={styles.container}>
          <div className={styles.top}>
            <h2>
              <i className="fas fa-credit-card" />
              <FormattedMessage id="credits" />
            </h2>
          </div>

          <ul className={styles.credits}>
            <li className={styles.credit}>
              <span className={styles.type}>
                <FormattedMessage id="credits_call_credits" />
              </span>
              <div className={styles.iconContainer}>
                <i className="fas fa-phone" />
              </div>
              <span className={styles.amount}>{account.call_credits}</span>
            </li>

            <li className={styles.credit}>
              <span className={styles.type}>
                <FormattedMessage id="credits_sms_credits" />
              </span>
              <div className={styles.iconContainer}>
                <i className="fas fa-comment-alt" />
              </div>
              <span className={styles.amount}>{account.sms_credits}</span>
            </li>

            <li className={styles.credit}>
              <span className={styles.type}>
                <FormattedMessage id="credits_push_credits" />
              </span>
              <div className={styles.iconContainer}>
                <i className="fas fa-mobile-alt" />
              </div>
              <span className={styles.amount}>{account.push_notification_credits}</span>
            </li>
          </ul>

        </section>


        <section className={styles.container}>
          <div className={styles.top}>
            <h2>
              <i className="fas fa-lock" />
              <FormattedMessage id="login_details" />
            </h2>

            <Button text="edit" onClick={openEditPasswordModal} icon="fas fa-cog" />
          </div>


          <div>
            <Label htmlFor="email" />
            <span>
              {account.email}
            </span>
          </div>


          <div>
            <Label htmlFor="password" />
            <span>
              ●●●●●●●●●
            </span>
          </div>

        </section>
      </>

    )
  })
}

export default AccountDetails;
