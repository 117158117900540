import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useObserver } from 'mobx-react-lite';

import { Modal, Button, Error } from '../elements';
import { closeModal } from '../../utils';
import { useError, useStores } from '../../hooks';
import { NewPasswordForm } from '../forms';
import { Messages } from '../I18n';

const EditPasswordModal = () => {
  const { settingsStore } = useStores(['settingsStore']);
  const { error, setError, clearError } = useError();

  const close = () => {
    closeModal('editPassword');
  };

  const cancel = () => {
    settingsStore.newPassword.cancel();
    close();
  };

  const resetPassword = async () => {
    try {
      clearError();
      await settingsStore.changePassword()
      Messages.success('changed_password');
      close();
    } catch (err) {
      console.log(err, 'error');
      if (err?.status) {
        switch (err?.status) {
          case 401:
            setError('invalid_password_supplied');
            break;
          default:
            setError('error_occured_in_the_server');
        }
      }
    }
  };

  return useObserver(() => {
    const { newPassword } = settingsStore;

    return (
      <Modal id="editPassword">
        <div className="modal-header">
          <h1>
            <FormattedMessage id="change_password"/>
          </h1>
          <i className="fas fa-times" onClick={close} />
        </div>
        <div className="modal-content">
          <Error error={error} activeErrors={newPassword.activeErrors} />
          <NewPasswordForm entity={newPassword} />
        </div>
        <div className="modal-footer">
          <Button text="save" onClick={resetPassword}/>
          <Button text="cancel" onClick={cancel}/>
        </div>
      </Modal> 
    )
  } )
};

export default EditPasswordModal;
