import { observable, decorate } from 'mobx';
import { param , Validator } from './';
import moment from 'moment';

class Notification {
  constructor(data) {
    this.validate = new Validator();
    this.id = param(data, 'id');
    this.message = param(data, 'message');
    this.on_accept = param(data, 'on_accept');
    this.on_decline = param(data, 'on_decline');
    this.state = param(data, 'state');
    this.action = param(data, 'action', {});
    this.type = param(data, 'type');
    this.expires_at = param(data, 'expires_at');
    this.date = param(data, 'date');
    this.actionType = this.action?.action || 'PASSIVE';
    this.displayDate = moment(this.date * 1000).format('DD/MM - HH:mm');
    this.performingCb = param(data, 'performingCb', false);
    

    this.selected = false;

    this.validation = {};
    this.validate.initializeErrors(this, this.validation);
  };

  getMessage = lang => {
    const langs = Object.keys(this.message);

    if (langs.includes(lang)) {
      return this.message[lang];
    }

    return this.message.en;
  };

  getShortMessage = lang => {
    return `${this.getMessage(lang).substr(0, 30)}...`
  };

  toJS = () => {
    return {
      id: this.id,
      message: this.message,
      on_accept: this.on_accept,
      on_decline: this.on_decline,
      state: this.state,
      type: this.type,
      expires_at: this.expires_at,
      date: this.date,
      parsed_date: this.displayDate
    }
  }
}

decorate(Notification, {
  id: observable,
  message: observable,
  on_accept: observable,
  on_decline: observable,
  state: observable,
  type: observable,
  expires_at: observable,
  selected: observable,
  performingCb: observable,
});

export default Notification;
