import React, { Component, Fragment } from 'react';
import { observer } from 'mobx-react';
import { Label, Input } from '../elements';

@observer
export default class CodeForm extends Component {
  render() {
    const { entity } = this.props;
    const { email } = entity;

    return (
      <Fragment>
        <div className="input">
          <Label htmlFor="label_email_invite" />
          <Input context={this} id="user" name="email" value={email} placeholder="placeholder_email_invite" />
        </div>
      </Fragment>
    );
  }
}
