import React from 'react';
import { useObserver } from 'mobx-react-lite';
import { FormattedMessage } from 'react-intl';

import { Modal, Error, Label, Input, Button, Info } from '../elements';
import { closeModal, controlOnErrors } from '../../utils';
import { useStores, useError } from '../../hooks';
import { Messages } from '../I18n';

const DeleteSelectedDeviceModal = () => {
  const { settingsStore, siteStore } = useStores(['settingsStore', 'siteStore']);
  const { error, setError, clearError } = useError('');

  const { account } = settingsStore;
  const close = () => {
    closeModal('deleteSelectedDevice');
    clearError();
    account.resetErrors()
  };

  const deleteDevice = async () => {
    const { account } = settingsStore;

    try {
      clearError();
      await controlOnErrors(account, account.passwordValidation);
      await siteStore.deleteDevice(account.password);
      Messages.success('deleted_device');
      account.reset();
      close();

    } catch (err) {
      if (err?.status) {
        switch (err?.status) {
          case 401:
            setError('invalid_password_supplied');
            break;
          default:
            setError('error_occured_in_the_server');
        }
      }
    }
  };

  return useObserver(() => {
    const { account } = settingsStore;

    return (
      <Modal id="deleteSelectedDevice" onClose={close}>
        <div className="modal-header">
          <h1 className="title">
            <FormattedMessage id="delete_device" />
          </h1>
          <i className="fas fa-times" onClick={close} />

        </div>

        <div className="modal-content">
          <Info info="info_delete_device" />

          <Error error={error} activeErrors={account.activeErrors}/>

          <div>
            <Label htmlFor="enter_your_password"/>
            <Input type="password" entity={account} name="password" placeholder="enter_your_password" />
          </div>
        </div>

        <div className="modal-footer">
          <Button text="delete_device" onClick={deleteDevice}/>
        </div>
      </Modal>
    )
  });
};

export default DeleteSelectedDeviceModal;

