import React, { useState, useEffect } from 'react';
import { useObserver } from 'mobx-react-lite';
import { FormattedMessage } from 'react-intl';
import { useParams } from 'react-router-dom';
import { translate } from '../I18n';
import { useRedirect } from '../../hooks';
import { legalStore, global } from '../../stores';

import styles from '../../assets/styles/legal.module.scss';

const LegalItem = ({ doc }) => {
  const [ isOpen, setIsOpen ] = useState(false);
  const [ htmlDocument, setDocument ] = useState(null);

  const toggleListItem = async () => {
    if (!isOpen) {
      try {
        const htmlDocument = await legalStore.getDocument(global.lang, doc.key);
        setDocument(htmlDocument);
      } catch (error) {
        console.log(error)
        setDocument(
          `
          <!DOCTYPE html>
          <html lang="en">
          <head>
            <meta charset="UTF-8">
            <title>No document found</title>
          </head>
          <body>
            <div>${translate('there_is_no_document_found')}</div>
          </body>
          </html>
          `
        )
      }

      setIsOpen(true);
    } else {
      setIsOpen(false);
    }
  };

  return useObserver(() => (
    <li>
      <div className={styles.collapsibleHeader} onClick={toggleListItem}>
        <span>{doc.description}</span>
        <i className="fas fa-chevron-down"></i>
      </div>
      {
        isOpen && (
          <div className={styles.collapsibleBody}>
            <div className={styles.legalDocument}>
              <div dangerouslySetInnerHTML={{__html: htmlDocument}} />
            </div>
          </div>
        )
      }
    </li>
  ))
}

const Legal = () => {
  const { redirect } = useRedirect();
  const { key } = useParams();
  const [ htmlDocument, setDocument ] = useState(null);

  const goToLogin = () => {
    redirect('/');
  };

  const getDocument = async () => {
     try {
      const htmlDocument = await legalStore.getDocument(global.lang, key);
      setDocument(htmlDocument);
    } catch (error) {
      console.log(error)
      setDocument(
        `
          <!DOCTYPE html>
          <html lang="en">
          <head>
            <meta charset="UTF-8">
            <title>No document found</title>
          </head>
          <body>
            <div>${translate('no_document_found')}</div>
          </body>
          </html>
          `
      )
    }
  };

  useEffect(() => {
    getDocument()
  }, [global.lang, key])

  return useObserver(() => {
    return (
      <section className={styles.page}>
        <h1 className="title">
          <FormattedMessage id="legal" />
        </h1>

        <div className={styles.legalDocument}>
          <div dangerouslySetInnerHTML={{__html: htmlDocument}} />
        </div>
      </section>
    )
  })
}

export default Legal



