import React from 'react';
import { List, Input, Label, Switch } from '../elements';
import locales from '../../locales.json';
import {useObserver} from 'mobx-react-lite';

class Title {
  constructor(id, title) {
    this.id = id;
    this.title = title;
  }
}

const AccountForm = ({ entity }) => {
  const titles = [
    new Title(1, 'Mr'),
    new Title(2, 'Mrs'),
  ];

  return useObserver(() => {
    return (
      <>
        <div className="input">
          <Label htmlFor="locale" />
          <List entity={entity} name="locale" list={locales} value="code" text="country" />
        </div>
        <div className="input">
          <Label htmlFor="title" />
          <List entity={entity} name="title" list={titles} value="id" text="title" />
        </div>

        <div className="input">
          <Label htmlFor="firstname" />
          <Input type="text" entity={entity} name="firstname" />
        </div>

        <div className="input">
          <Label htmlFor="lastname" />
          <Input type="text" entity={entity} name="lastname" />
        </div>
        <div className="input">
          <Label htmlFor="email" />
          <Input type="text" entity={entity} name="email" />
        </div>

        <div className="input">
          <Label htmlFor="mobile" />
          <Input type="text" entity={entity} name="mobile" />
        </div>

        <div>
          <Label htmlFor="empersonation_enabled" />
          <Switch 
            entity={entity} 
            name="imp_enabled"
            onLabel="allowed"
            offLabel="not_allowed"
          />
        </div>
      </>
    )
  });
};

export default AccountForm;
