import React from 'react';
import Loader from 'react-loader-spinner'

const Spinner = ({ width = 200, height = 200}) => (
  <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
    <Loader
      id="test"
      type="Oval"
      color="#003D6D"
      height={height}
      width={width}
    />
  </div>
);

export default Spinner;
