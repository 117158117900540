import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { FormattedMessage } from 'react-intl';

@observer
export default class Info extends Component {
  render() {
    const { info, ...rest } = this.props;
    return (
      <div className="info-page" {...rest}>
        <i className="fa fa-info-circle" aria-hidden="true"></i>
        <FormattedMessage id={info} />
      </div>
    )
  }
}

