import React, { useContext, useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useHistory } from 'react-router-dom';

import { storeContext } from './context';
import { translate } from './components/I18n';

export const useStore = store => {
  return useContext(storeContext)[store];
};

export const useStores = requestedStores => {
  const stores = useContext(storeContext);

  return requestedStores.reduce((storesToReturn, requestedStore) => {
    if (stores[requestedStore] !== undefined) {
      storesToReturn[requestedStore] = stores[requestedStore];
    }

    return storesToReturn;
  }, {});
};

export const useRedirect = () => {
  const history = useHistory();

  const redirect = location => {
    history.push(location);
  };

  return {
    redirect,
  };
};

export const useError = defaultState => {
  const [error, setError] = useState(defaultState);
  const [errors, setErrors] = useState([]);

  const clearError = () => {
    if (error !== '') {
      setError('');
      setErrors([]);
    }
  };

  const setHtmlError = error => {
    if (!Array.isArray(error)) {
      console.log(error);
    }

    const writeErrors = () => {
      const content = (
        <div>
          <h2 className="errortitle">
            <FormattedMessage id="please_resolve_the_following_errors" />
          </h2>
          <ul>
            {
              error.map((error, index) => (
                <li key={index}>
                  <FormattedMessage id={error.error} />
                </li>
              ))
            }
          </ul>
        </div>
      );

      setErrors(error);
      setError(content);
    };

    const currentErrors = JSON.stringify(errors);
    const newErrors = JSON.stringify(error);

    if (currentErrors !== newErrors) {
      writeErrors();
    }
  };

  const setTranslatedError = error => {
    setError(translate(error));
  };

  return {
    error,
    clearError,
    setError: setTranslatedError,
    setHtmlError,
  };
};

export const useUnmount = cb => {
  return useEffect(() => {
    return cb;
  }, [cb]);
};

export const useWidthValidator = () => {
  const width = window.innerWidth;
  const height = window.innerHeight;
  const [ isMobile, setIsMobile ] = useState(width <= 800 || height <= 820);
  const [ windowWidth, setWindowWidth ] = useState(width);

  useEffect(() => {
    const setWidth = () => {
      const width = window.innerWidth;
      const height = window.innerHeight;
      setWindowWidth(width);
      if ((width <= 800 || height <= 820) && !isMobile) {
        setIsMobile(true)
      } else if ((width > 800 && height > 820) && isMobile) {
        setIsMobile(false);
      }
    };

    window.addEventListener('resize', setWidth);

    return () => {
      window.removeEventListener('resize', setWidth);
    }
  }, [isMobile]);

  return {
    isMobile,
    width: windowWidth,
  };
};

export const useModal = () => {
  const modalStore = useStore('modalStore');

  const register = (id, cb) => {
    modalStore.registerModal({ id, cb });
  };

  const unregister = id => {
    modalStore.unregisterModal(id);
  };

  return {
    register,
    unregister,
  };
};

export const useTitle = (title) => {
  useEffect(() => {
    document.title = translate('user_management_page_title');
  }, []);
};


// export const useDebounce = (callback, deps) => {
//   const [ result, setResult ] = useState('');
//   const [ value, setValue ] = useState(JSON.stringify(deps));
//   const [ timeout, setTimeoutHandler ] = useState(null);
//   const [ lastTime, setLastTime ] = useState(Date.now());

//   const execute = async () => {
//     const result = await callback()
//     setResult(result);
//   };

//   useEffect(() => {
//     if (lastTime + 1000 < Date.now()) {
//       setLastTime(Date.now());
//       execute();
//     } else {
//       if (timeout !== null) {
//         clearTimeout(timeout);
//       }

//       const newTimeout = setTimeout(execute, (lastTime + 1000 - Date.now()))

//       setTimeoutHandler(newTimeout)
//     }
//   }, [value]);

//   useEffect(() => {
//     setValue(JSON.stringify(deps));
//   }, deps)

//   return {
//     result,
//   };
// }

export const useDebounce = (callback, deps) => {
  const [result, setResult] = useState('');
  const [value, setValue] = useState(JSON.stringify(deps));
  const [timeout, setTimeoutHandler] = useState(null);
  const [lastTime, setLastTime] = useState(Date.now());

  const execute = async () => {
    if (lastTime + 500 < Date.now()) {
      const result = await callback();
      setResult(result);
    } else {
      if (timeout !== null) {
        clearTimeout(timeout);
      }

      const newTimeout = setTimeout(execute, (lastTime + 500 - Date.now()));
      setTimeoutHandler(newTimeout);
    }

    setLastTime(Date.now());
  };

  useEffect(() => {
    execute();
  }, [value]);

  useEffect(() => {
    setValue(JSON.stringify(deps));
  }, deps);

  return {
    result,
  };
};

export const useEscape = cb => {
  useEffect(() => {
    const execute = ev => {
      if (ev.keyCode === 27) {
        cb();
      }
    };

    document.addEventListener('keydown', execute);
    return () => {
      document.removeEventListener('keydown', execute);
    };
  }, []);
};
