import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import { FormattedMessage } from 'react-intl';
import { Spinner, Button } from '../elements';
import { URL, doFetch } from '../../utils';
import { Messages } from '../I18n';

import cross_circle from '../../assets/images/cross.svg';
import check_circle from '../../assets/images/check-circle.svg';

@inject('siteStore')
@observer
export default class AcceptOwnership extends Component {
  constructor() {
    super();
    this.state = {
      activating: true,
      status: false,
    };
  }

  componentDidMount = () => {
    const { match } = this.props;
    const { email, site, token } = match.params;

    const config = {
      email,
      site,
      token,
    };

    doFetch(`${URL}/capi/site/accept/transfer`, 'POST', config)
      .then(() => {
        Messages.succes('accepted_ownership');
        this.setState({ status: true, activating: false });
      })
      .catch(err => {
        console.log(err);
        this.setState({ status: false, activating: false });
        Messages.error('failed_to_accept_ownership');
      });
  };

  render() {
    const { activating, status } = this.state;
    return (
      <div>
        <h1><FormattedMessage id="accept_ownership" /></h1>

        {
          activating && (
            <Spinner />
          )
        }

        {
          !activating && !status && (
            <div>
              <img src={cross_circle} className="checked_image" alt="checked circle" width="11%" />
              <h2 style={{ fontFamily: 'raleway', color: '#444', fontSize: '2.3rem', textAlign: 'center' }}>
                <FormattedMessage id="failed_to_activate_your_ownership" />
              </h2>
            </div>
          )
        }

        {
          !activating && status && (
            <div>
              <img src={check_circle} className="checked_image" alt="checked circle" width="11%" />
              <h2 style={{ fontFamily: 'raleway', color: '#444', fontSize: '2.3rem', textAlign: 'center' }}>
                <FormattedMessage id="successfully_activated_ownership_for_site" />
              </h2>
            </div>
          )
        }

        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <Button text="go_to_the_overview_of_your_sites" onClick={() => this.props.history.push('/')}/>
        </div>
      </div>
    );
  }
}
