import React, {useState} from 'react';
import { FormattedMessage } from 'react-intl';
import { useObserver } from "mobx-react-lite";

import { Messages } from '../I18n';
import { Modal, Label, Button, Error } from '../elements';
import { useStores } from '../../hooks';
import { closeModal, controlOnErrors } from '../../utils';
import { SiteForm } from '../forms';
import { Cropper } from '../partials';

const VIEWS = {
  OVERVIEW: 'OVERVIEW',
  EDIT_IMAGE: 'EDIT_IMAGE'
}

const EditSiteModal = () => {
  const { siteStore } = useStores(['siteStore']);
  const [ file, setFile ] = useState(undefined);
  const [view, setView] = useState(VIEWS.OVERVIEW);
  const [functions, setFunctions] = useState({});
  // const functions = {};

  const updateSite = async () => {
    try {
      await controlOnErrors(siteStore.selectedSite);
      await siteStore.updateSite();
      closeModal('editSite');
      Messages.success('updated_site');
    } catch (err) {
      if (!Array.isArray(err)) {
        Messages.error('failed_to_update_site');
      }
    }
  };

  const close = () => {
    closeModal('editSite');
  };

  const changeImage = ev => {
    if (ev.target.files && ev.target.files.length > 0) {
      setFile(ev.target.files[0])
    }

    setView(VIEWS.EDIT_IMAGE);
  };

  const useImageAndSasve = () => {
    const { selectedSite } = siteStore;
    console.log(functions)
    const cropData = functions.getCropData();
    selectedSite.image = cropData;
    updateSite();
  };

  return useObserver(() => {
    const { selectedSite } = siteStore;

    return (
      <Modal id="editSite">
        <div className="modal-header">
          <h1>
            <FormattedMessage id="edit_site" />
          </h1>

          <i className="fas fa-times" onClick={close} />
        </div>

        <div className="modal-content">
          {
            view === VIEWS.OVERVIEW && (
              <>
                <Error activeErrors={selectedSite.activeErrors}/>
                <SiteForm entity={selectedSite} inModal />

                <Label htmlFor="site_image" />
                <img src={selectedSite.image} alt="image of the site" />
              </>
            )
          }

          {
            view === VIEWS.EDIT_IMAGE && (
              <Cropper 
                entity={selectedSite} 
                selectedFile={file} 
                functions={functions}
              />
            )
          }
        </div>

        <div className="modal-footer">

          {
            view === VIEWS.OVERVIEW && (
              <>
                <Button text="update_site" onClick={updateSite}/>
                <label htmlFor="edit_site_file" className="btn" style={{marginRight: '10px'}}>
                  <FormattedMessage id="change_image" />
                </label>

                <input id="edit_site_file" type="file" onChange={ev => changeImage(ev)} />
              </>
            )
          }

          {
            view === VIEWS.EDIT_IMAGE && (
              <>
                <Button text="use_image_and_update_site" onClick={useImageAndSasve}/>
              </>

            )
          }

          <Button text="cancel" onClick={close}/>
        </div>
      </Modal>
    )
  })
};

export default EditSiteModal;


