import React, { Fragment, Component } from 'react';
import { Header, Footer } from './global';

export default function Page(WrapperComponent, width = '80%') {
  /* eslint-disable */
  class Wrapper extends Component {
    render() {
      return (
        <Fragment>
          <Header {...this.props}/>
          <main style={{ width }}>
            <WrapperComponent {...this.props} />
          </main>
          <Footer {...this.props}/>
        </Fragment>
      );
    }
  }

  return Wrapper;
}
