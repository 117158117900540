import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Button, Info, Modal } from '../elements';
import { TransferForm } from '../forms';
import {useObserver} from 'mobx-react-lite';

const TransferModal = ({ transfer, entity, site }) => { 
  return useObserver(() => {
    return (
      <Modal id="transferModal" className="modal modal-fixed-footer">
        <div className="modal-header">
          <h1>
            <FormattedMessage id="transfer_modal" values={{ name: site.name }} />
          </h1>
        </div>
        <div className="modal-content">
          <Info info="info_transfer_site" />
          <TransferForm context={this} entity={entity} />
        </div>
        <div className="modal-footer">
          <Button className="btn-flat" onClick={transfer} text="transfer_ownership" />
        </div>
      </Modal>
    )
  })
};

export default TransferModal;
