import { observable } from 'mobx';
import { SSO_URL } from '../../utils';
import Validator from './Validator';
import param from './Param';

export default class User {
  @observable email;
  @observable password;

  constructor(context, data) {
    this.context = context;
    this.URLS = {
      LOGIN: `${SSO_URL}/api/sso/account/login`,
      RESET: `${SSO_URL}/api/sso/account/reset`,
    };

    this.validate = new Validator();
    this.email = param(data, 'email', 'jens@dptechnics.com');
    this.password = param(data, 'password', 'Ditiseentest1');


    this.validation = {
      email: this.validate.input,
      password: this.validate.input,
    };

    this.validate.initializeErrors(this, this.validation);
  }


  toJS = () => {
    return {
      email: this.email,
      password: this.password,
    };
  };
}
