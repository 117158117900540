import React from 'react';
import { useObserver} from 'mobx-react-lite';
import { IntlProvider, addLocaleData } from 'react-intl';
import { ToastContainer } from 'react-toastify';

import en from 'react-intl/locale-data/en';
import fr from 'react-intl/locale-data/fr';
import de from 'react-intl/locale-data/de';
import nl from 'react-intl/locale-data/nl';
import it from 'react-intl/locale-data/it';
import es from 'react-intl/locale-data/es';
import pl from 'react-intl/locale-data/pl';

import '@fortawesome/fontawesome-free/css/all.css'
import 'react-toastify/dist/ReactToastify.min.css';
import '../assets/styles/screen.scss';

import Router from './Router';
import locales from '../assets/locales/locales.json';
import {useStores} from '../hooks';

addLocaleData([...en, ...fr, ...nl, ...de, ...es, ...it, ...pl]);

const App = () => {
  const { global } = useStores(['global']);

  return useObserver(() => {
    const { lang } = global;

    const keys = Object.keys(locales);
    const values = Object.values(locales);

    const messages = keys.reduce((obj, key, index) => {
      obj[key] = values[index][lang.toLowerCase()];
      return obj;
    }, {});

    return (
      <IntlProvider key={lang} locale={lang} messages={messages}>
        <>
          <ToastContainer />
          <Router />
        </>
      </IntlProvider>
    )
  })
}

export default App;
