import React from 'react'

export default ({columns, ...rest}) => (
  columns.map((column, index) => (
    <div key={index} onClick={column.changeStatus} className={column.hideOnMobile ? 'hideOnMobile' : ''}  style={{cursor: 'pointer'}}>
      <span>{column.columnName}</span>
      <i aria-hidden="true" className={`fa fa-${column.displayStatus}`} style={{marginLeft: '10px'}}></i>
    </div>
  ))
);
