import { useObserver } from 'mobx-react-lite';
import React, {useEffect} from 'react';
import { getValue, setValue } from './IO';

const List = props => { 
  const { text, value, list = [], disableList = [], ...rest } = props;

  useEffect(() => {
    const listValues = list.map(obj => obj[value].toString());
    const objectValue = getValue(props);

    const availableValues = listValues.filter(value => (
      !disableList.includes(value.toString())
    ));

    if (!availableValues.includes(objectValue.toString()) && availableValues.length > 0) {
      setValue(props, availableValues[0])
    }
  });

  const onChange = ev => {
    setValue(props, ev.target.value);
  };

  return useObserver(() => {
    const options = list.map(item => (
      <option 
        key={item[value]} 
        value={item[value]} 
        disabled={disableList.includes(item[value])}
      >
        {item[text]}
      </option>
    ));

    return (
      <select 
        value={getValue(props)} 
        onChange={onChange}
        {...rest}
      >
        {options}
      </select>
    )
  })
}

export default List;
