import React, { useEffect } from 'react';
import { FormattedMessage } from 'react-intl';

import { openModal } from '../../utils';
import { Messages, translate } from '../I18n';

import { useObserver } from 'mobx-react-lite';
import { useRedirect, useStores } from '../../hooks';
import { Button, Spinner } from '../elements';
import { NewSiteModal } from '../modals';

import defaultImage from '../../assets/images/default.png';
import emptyResult from '../../assets/images/search.svg';

import styles from '../../assets/styles/site.module.scss';
import { SearchForm } from '../forms';

const Home = () => {
  const { siteStore, deviceStore, global } = useStores(['siteStore', 'deviceStore', 'global']);
  const { redirect } = useRedirect();

  useEffect(() => {
    siteStore.getAll();
  }, [])

  const connectToBoard = async site => {
    const { id } = site;
    const devices = await deviceStore.getAll(id);
    const onlineDevices = devices.filter(device => device.dev_state === true);

    if (devices.length === 0) {
      Messages.error(translate('there_are_no_devices_linked_to_this_site'));
    } else if (onlineDevices.length === 0) {
      Messages.error(translate('there_are_no_online_devices_in_this_site'));
    } else {
      // window.location.href = `https://${id.replace(/-/g, '')}.site.came.io`;
      const deviceToConnect = onlineDevices[0];
      const { token } = await deviceToConnect.connect();
      const url = encodeURI(`https://sso.bluecherry.io/proxy/device/login/${token}/${deviceToConnect.access_id}`);

      window.location.href = url;

      // if(window.location.host.includes("came")){
      //   window.location.href=  `https://${id.replace(/-/g, '')}.site.came.io`;
      // }
      // else {

      //   const deviceToConnect = onlineDevices[0];
      //   const { token } = await deviceToConnect.connect();
      //   const url = encodeURI(`https://sso.bluecherry.io/proxy/device/login/${token}/${deviceToConnect.access_id}`);
  
      //   window.location.href = url;
      // }
    }
  };

  const goToSiteSettings = site => {
    redirect(`/site_overview/${site.id}`);
  };

  return useObserver(() => {
    const { sites, loading } = siteStore;
    const { searchterm } = global;

    const sitesToRender = sites.filter(site => site.name.toLowerCase().includes(searchterm.toLowerCase()));

    return (
      <div className="home">

        <div className={styles.title}>
          <h1 className="title">
            <FormattedMessage id="site_overview" />
          </h1>

          <Button
            text="add_new_site"
            onClick={() => openModal('newSite')}
            icon="fas fa-plus"
          />
        </div>

        <NewSiteModal />

        {
          loading && (
            <Spinner />
          )
        }

        {
          !loading && sites.length === 0 && (
            <div className="imgPlaceholder">
              <img src={emptyResult} alt="search" style={{ maxWidth: '440px' }} />
              <FormattedMessage id="there_are_currently_no_sites_linked_to_your_account" />
            </div>
          )
        }

        {
          !loading && sites.length > 0 && (
            <>
              <SearchForm />
              <div className={styles.grid}>
                {
                  sitesToRender.map(site => {
                    return (
                      <div key={site.id}>
                        <div className={styles.card}>
                          <div className={styles.site}>
                            <i className="fas fa-cog" onClick={() => goToSiteSettings(site)} />
                            <div className={styles.overlay} onClick={() => connectToBoard(site)}>
                              <FormattedMessage id="open_site" />
                            </div>
                            <img src={site.image !== '' ? site.image : defaultImage} alt="default img" width="100%" />
                            <span className={styles.dot} style={{ backgroundColor: site.statusColor }} />
                          </div>
                          <div className={styles.title}>
                            <span className={styles.name}>{site.name.replaceAll('_', ' ')}</span>
                          </div>
                        </div>
                      </div>
                    );
                  })
                }
              </div>
            </>
          )
        }

      </div>
    )
  });
};

export default Home;
