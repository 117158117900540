import React from 'react';
import { useObserver } from 'mobx-react-lite';
import { FormattedMessage } from 'react-intl'
import { Button, Info, Modal } from '../elements';
import { InviteForm } from '../forms';


const InviteUser = ({ entity, invite }) => { 
  return useObserver(() => {
    return (
      <Modal id="inviteUser" className="modal modal-fixed-footer">
        <div className="modal-header">
          <h1>
            <FormattedMessage id="invite_adminsitrator" />
          </h1>
        </div>
        <div className="modal-content">
          <Info info="info_invite_form" />
          <InviteForm context={this} entity={entity} />
        </div>

        <div className="modal-footer">
          <Button className="btn-flat modal-close" text="cancel" />
          <Button className="btn-flat" onClick={invite} text="invite_adminsitrator" />
        </div>
      </Modal>
    )
  })
};


export default InviteUser;
