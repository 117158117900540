import { Model, param, Validator } from '.';
import { decorate, observable } from 'mobx';

class DeviceClaim extends Model {
  constructor(context, data) {
    super(context, data);
    this.validate = new Validator();
    this.init(data);

    this.validation = {
      access_id: input => this.validate.input(input, 'enter_a_valid_access_id'),
      name: input => this.validate.input(input, 'enter_a_valid_device_name'),
      password: input => this.validate.input(input, 'enter_your_password'),
    };

    this.validate.initializeErrors(this, this.validation);
  }

  init = (data) => {
    this.access_id = param(data, 'access_id');
    this.name = param(data, 'name');
    this.connection_method = param(data, 'connection_method');
    this.resolve_mechanism = param(data, 'resolve_mechanism', 3);
    this.device = undefined;
    this.password = '';
  };

  toJS = () => {
    return {
      access_id: this.access_id,
      name: this.name,
      connection_method: this.connection_method,
      resolve_mechanism: this.resolve_mechanism,
      password: this.password
    };
  };
};

decorate(DeviceClaim, {
  resolve_mechanism: observable,
  connection_method: observable,
  name: observable,
  access_id: observable,
  password: observable
});

export default DeviceClaim;
