import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useObserver } from 'mobx-react-lite';

import { Modal, Label, Input, Error } from '../elements';
import { closeModal, controlOnErrors, handleError, openModal } from '../../utils';
import { useStores, useError } from '../../hooks';

import styles from '../../assets/styles/sharedUsers.module.scss'
import {Messages, translate} from '../I18n';
import {ModalButton} from '../models';

const EditSharedUsersModal = () => {
  const { sharedUsersStore, global } = useStores(['sharedUsersStore', 'global']);
  const { error, setError, clearError } = useError('');

  const close = () => {
    closeModal('editSharedUsers');
  };

  const addNewSharedUser = async () => {
    const { newSharedUser } = sharedUsersStore;
    try {
      clearError();
      await controlOnErrors(newSharedUser, newSharedUser.newUserValidation);
      await sharedUsersStore.addNewSharedUser();
      Messages.success('added_shared_user', { email: newSharedUser.email });
    } catch (err) {
      if (err?.status) {
        handleError(err, (status, body) => {
          if (status === 406 && body.code === 770) {
            setError('the_email_address_is_yours');
          } else if (status === 406 ) {
            setError('the_email_address_already_exists');
          } else if (status === 402) {
            setError('not_enough_credits_to_add_another_user');
          } else {
            Messages.error('failed_to_add_shared_user', { email: newSharedUser.email });
          }
        })
      }
    }
  };

  const updateSharedUser = async sharedUser => {
    try {
      await sharedUsersStore.updateSharedUser(sharedUser);
      sharedUser.toggleEditing();
      Messages.success('updated_shared_user', { email: sharedUser.email});
    } catch {
      Messages.error('failed_to_update_user', { email: sharedUser.email });
    }
  };

  const deleteSharedUser = sharedUser => {
    try {
      sharedUsersStore.deleteSharedUser(sharedUser);
      Messages.success('deleted_shared_user', { email: sharedUser.email });
    } catch {
      Messages.error('failed_to_delete_shared_user', { email: sharedUser.email });
    }
  };

  const showDeleteWarning = sharedUser => {

    closeModal('editSharedUsers');

    const title = translate('delete_shared_user', {name: sharedUser.name || sharedUser.email});
    const message = translate('are_you_sure_you_want_to_delete_shared_user', { name: sharedUser.name || sharedUser.email });
    const buttons = [
      new ModalButton('delete', () => deleteSharedUser(sharedUser)),
      new ModalButton('cancel', () => openModal('editSharedUsers'), true)
    ];

    global.displayWarningModal(title, message, buttons);
  };
  
  const onKeyDown = (ev, cb) => {
    if (ev.key === 'Enter') {
      cb();
    }
  };

  const resendInvite = async sharedUser => {
    await sharedUsersStore.resendInvite(sharedUser);
    Messages.success('resent_invitation');
  };

  return useObserver(() => {
    const { sharedUsers, newSharedUser } = sharedUsersStore;

    return (
      <Modal id="editSharedUsers" className={styles.sharedUsersModal} onClose={sharedUsersStore.resetSharedUser}>
        <div className="modal-header">
          <h1>
            <FormattedMessage id="edit_shared_users"/>
          </h1>
          <i className="fas fa-times" onClick={close} />
        </div>
        <div className="modal-content">
          <Error error={error} activeErrors={newSharedUser.activeErrors} />

          <ul className={styles.sharedUsers}>
            {
              sharedUsers.map(sharedUser => (
                <li key={sharedUser.id} className={styles.sharedUser} onKeyDown={ev => onKeyDown(ev, () => updateSharedUser(sharedUser))}>
                  <div className={styles.information}>
                    {
                      !sharedUser.editing && (
                        <>
                          <span className={styles.invited}>
                            {
                              !sharedUser.linked && sharedUser.invited && (
                                <strong onClick={() => resendInvite(sharedUser)}>
                                  <FormattedMessage id="resend_invite" />
                                </strong>
                              )
                            }
                          </span>
                          <span className={styles.name}>
                            {sharedUser.displayName}
                          </span>
                          <span>{sharedUser.displayEmail}</span>
                        </>
                      )
                    }

                    {
                      sharedUser.editing && (
                        <>
                          <Input 
                            entity={sharedUser} 
                            name="name" 
                            placeholder="Vul de naam in"
                          />
                          <span className={styles.editing}>{sharedUser.email}</span>
                        </>
                      )
                    }
                  </div>

                  {
                    !sharedUser.editing && (
                      <div className={styles.actions}>
                        <i 
                          className="fas fa-edit"
                          onClick={sharedUser.toggleEditing}
                        />

                        <i 
                          className="fas fa-trash"
                          onClick={() => showDeleteWarning(sharedUser)}
                        />
                      </div>
                    )
                  }

                  {
                    sharedUser.editing && (
                      <div className={styles.actions}>
                        <i className="fas fa-save" onClick={() => updateSharedUser(sharedUser)}/>

                        <i className="fas fa-times" onClick={sharedUser.toggleEditing} />
                      </div>
                    )
                  }
                  
                </li>
              ))
            }

            <li className={styles.newSharedUser} onKeyDown={ev => onKeyDown(ev, addNewSharedUser)}>
              <div className={styles.inputs}>
                <Input 
                  entity={newSharedUser} 
                  name="name" 
                  placeholder="Vul de naam in"
                />

                <Input 
                  entity={newSharedUser} 
                  name="email" 
                  placeholder="Vul het E-mail adres in"
                />
              </div>

              <i className="fas fa-plus" onClick={addNewSharedUser}/>
            </li>
          </ul>
        </div>
      </Modal> 
    )
  } )
};

export default EditSharedUsersModal;
