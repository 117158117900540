import React, { Component } from 'react';
import { Spinner } from '../elements';

export default class DeviceLogin extends Component {
  componentDidMount = () => {
    const { token, access_id } = this.props.match.params;
    if (token !== undefined && access_id !== undefined) {
      const domain = 'bluecherry.io';
      const cookieName = 'device_token';
      const cookieValue = token;
      const myDate = new Date();
      myDate.setMonth(myDate.getMonth() + 12);
      document.cookie = `${cookieName}=${cookieValue};expires=${myDate};domain=${domain};path=/`;
      window.location.href = `https://${access_id}.platform.bluecherry.io`;
    } else {
      this.props.history.push('/');
    }
  };

  render() {
    return (
      <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
        <h1 className="title" style={{ marginTop: '100px' }}>Loging in to your device</h1>
        <Spinner />
      </div>
    );
  }
}
