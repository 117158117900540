import { decorate, observable } from 'mobx';
import { Validator, param, Model } from '.';

class NewPassword extends Model{
  constructor(context, data) {
    super(context, data);
    this.validate = new Validator();
    this.init(data);
    

    this.validation = {
      oldPassword: input => this.validate.input(input, 'error_old_password'),
      newPassword: input => this.validate.input(input, 'error_new_password'),
      repeatPassword: () => this.validate.doubleParameter(this.newPassword, this.repeatPassword, 'error_repeat_password'),
    };

    this.validate.initializeErrors(this, this.validation);
  };

  init = data => {
    this.oldPassword = param(data, 'oldPassword');
    this.newPassword = param(data, 'newPassword');
    this.repeatPassword = param(data, 'repeatPassword');
  };

  toJS = () => {
    return {
      newPassword: this.newPassword,
      oldPassword: this.oldPassword
    };
  };
};

decorate(NewPassword, {
  oldPassword: observable,
  newPassword: observable,
  repeatPassword: observable,
});

export default NewPassword;
