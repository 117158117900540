import moment from 'moment';
import { decorate, observable, observe } from 'mobx';
import { param, Device, Validator, Model } from '.';

export default class Site extends Model {

  constructor(context, data) {
    super(context, data);
    this.validate = new Validator();
    this.init(data);

    this.validation = {
      name: input => this.validate.input(input, 'enter_a_valid_site_name'),
    };

    this.validate.initializeErrors(this, this.validation);
  }

  init = (data) => {
    this.id = param(data, 'id');
    this.name = param(data, 'name');
    this.code = param(data, 'code');
    this.image = param(data, 'image');
    this.latitude = param(data, 'latitude');
    this.longitude = param(data, 'longitude');
    this.state = param(data, 'state', 0);
    this.devices = param(data, 'devices', [], Device);
    this.transferinfo = param(data, 'transferinfo');
    this.dealer_access = param(data, 'dealer_access');
    this.da_expires_at = param(data, 'da_expires_at');
    this.offline_notifications_enabled = param(data, 'offline_notifications_enabled');

    this.display_expires_at = moment(this.da_expires_at * 1000).format('DD/MM/YYYY  HH:mm');
    this.infinite_access = parseInt(this.da_expires_at) === -1;

    observe(this, 'infinite_access', ({ newValue }) => {
      if (newValue) {
        this.da_expires_at = -1; 
      } else {
        this.da_expires_at = moment().add(1, 'week').unix() 
      }
    });
    

    if (this.state !== '') {
      switch (this.state) {
        case 0:
          this.statusColor = '#e62929';
          break;
        case 1:
          this.statusColor = '#25cc2a';
          break;
        case 2:
          this.statusColor = '#f0a511';
          break;
        case 3:
          this.statusColor = '#ccc';
          break;
        default:
          break;
      }
    }
  }

  toJS = () => {
    return {
      id: this.id,
      name: this.name,
      code: this.code,
      latitude: this.latitude,
      longitude: this.longitude,
      image: this.image,
      devices: this.devices.map(device => device.toJS()),
      state: this.state,
      da_expires_at: this.da_expires_at,
      offline_notifications_enabled: this.offline_notifications_enabled,
      dealer_access: this.dealer_access,
    };
  };
}

decorate(Site, {
  id: observable,
  name: observable,
  code: observable,
  latitude: observable,
  longitude: observable,
  image: observable,
  devices: observable,
  state: observable,
  statusColor: observable,
  transferinfo: observable,
  offline_notifications_enabled: observable,
  dealer_access: observable,
  da_expires_at: observable,
  display_expires_at: observable,
  infinite_access: observable
});
