import { observable, decorate, computed } from 'mobx';
import { param, Validator, Model } from './';

class SharedUser extends Model {
  constructor(data) {
    super(undefined, data);
    this.validate = new Validator();

    this.id = param(data, 'account', param(data, 'account'));
    this.account = param(data, 'account', param(data, 'account'));
    this.shared_account = param(data, 'shared_account');
    this.name = param(data, 'name');
    this.email = param(data, 'email');
    this.name = param(data, 'name');
    this.linked = param(data, 'linked', false);
    this.shared = param(data, 'shared', false);
    this.owner = param(data, 'owner', false);
    this.invited = param(data, 'invited', true);
    this.state = param(data, 'state');
    this.editing = param(data, 'editing', false);
    this.performingCb = param(data, 'performingCb', false);

    this.shared = this.state === 'SHARED' || this.state === 'INVITED';

    this.validation = {
      email: input => this.validate.email(input, 'enter_a_valid_email_for_the_shared_user')
    };
    
    this.newUserValidation = {
      email: input => this.validate.email(input, 'enter_a_valid_email_for_the_shared_user'),
      name: input => this.validate.input(input, 'enter_a_valid_name_for_the_shared_user')
    };

    this.validate.initializeErrors(this, {
      ...this.validation,
      ...this.newUserValidation
    });
  };

  toggleEditing = () => {
    this.editing = !this.editing;
  };

  toJS = () => {
    return {
      account: this.account,
      email: this.email,
      name: this.name === '' ? this.email : this.name,
      shared_account: this.shared_account,
      linked: this.linked,
      shared: this.shared,
    }
  }

  get displayName() {
    if (this.name === '') {
      return this.displayEmail;
    }

    return this.name.length > 25 ? `${this.name.substr(0, 25)}...`: this.name;
  }

  get displayEmail() {
    return this.email.length > 25 ? `${this.email.substr(0, 25)}...`: this.email;
  }
}

decorate(SharedUser, {
  id: observable,
  account: observable,
  linked: observable,
  shared: observable,
  shared_account: observable,
  email: observable,
  name: observable,
  editing: observable,
  invited: observable,
  performingCb: observable,
  displayEmail: computed,
  displayName: computed,
  owner: observable,
});

export default SharedUser

