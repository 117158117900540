import { observable, observe, decorate, computed } from 'mobx';
import { Validator, param, Model, Title } from '.';

export default class Account extends Model {
  @observable email;
  @observable firstname;
  @observable lastname;
  @observable locale;
  @observable mobile;
  @observable title;
  @observable devices;
  @observable device_groups;
  @observable max_devices;
  @observable first_expire_time;
  @observable call_credits;
  @observable sms_credits;
  @observable shared_users_credits;

  @observable address1;
  @observable address2;
  @observable vat;
  @observable country;
  @observable type;

  @observable push_notification_credits;
  @observable devices_lost;
  @observable credit_info;
  @observable company_name;
  @observable name;
  @observable address_country;
  @observable valid_vat;
  @observable purchaseOrder;
  @observable validating = false;
  @observable imp_enabled = false;

  @observable shared_user_error = true;
  @observable password;
  @observable newEmail;

  constructor(context, data) {
    super(context, data);
    this.validate = new Validator();
    this.init(data);
    this.validate.initializeErrors(this, {
      ...this.passwordValidation,
      ...this.transferValidation,
      ...this.validation,
    });
  };

  init = data => {
    this.password = param(data, 'password');

    this.email = param(data, 'email');
    this.firstname = param(data, 'firstname');
    this.lastname = param(data, 'lastname');
    this.locale = param(data, 'locale', 'nl-BE');
    this.mobile = param(data, 'mobile');
    this.email = param(data, 'email');
    this.title = param(data, 'title');
    this.devices = param(data, 'devices', []);
    this.device_groups = param(data, 'device_groups', []);
    this.max_devices = param(data, 'max_devices');
    this.first_expire_time = param(data, 'first_expire_time');
    this.call_credits = param(data, 'call_credits');
    this.sms_credits = param(data, 'sms_credits');
    this.push_notification_credits = param(data, 'push_notification_credits');
    this.credit_info = param(data, 'credit_info', []);
    this.address1 = param(data, 'address1');
    this.address2 = param(data, 'address2');
    this.vat = param(data, 'vat');
    this.type = param(data, 'type', 'private');
    this.devices_lost = param(data, 'devices_lost');
    this.company_name = param(data, 'company_name');
    this.name = param(data, 'name', `${this.firstname} ${this.lastname}`);
    this.address_country = param(data, 'address_country');
    this.valid_vat = param(data, 'valid_vat', false);
    this.imp_enabled = param(data, 'imp_enabled', false);
    this.too_many_shared_users = param(data, 'too_many_shared_users', false);

    this.password = param(data, 'password');
    this.newEmail = param(data, 'newEmail');
    this.purchaseOrder = param(data, 'purchaseOrder');

    this.validation = {
      email: input => this.validate.email(input, 'enter_a_valid_email'),
      firstname: input => this.validate.input(input, 'enter_a_valid_first_name'),
      lastname: input => this.validate.input(input, 'enter_a_valid_last_name'),
      locale: this.validate.input,
      mobile: input => this.validate.input(input, 'enter_a_valid_telno'),
      title: this.validate.input
    };

    this.passwordValidation = {
      password: input => this.validate.password(input, 'enter_a_valid_password'),
    }

    this.transferValidation = {
      newEmail: input => this.validate.email(input, 'enter_a_valid_email'),
      password: input => this.validate.password(input, 'enter_a_valid_password'),
    }

    observe(this, 'type', () => {
      this.valid_vat = false;
    });
  }

  get displayName() {
    const titles = [
      new Title(1, 'Mr'),
      new Title(2, 'Mrs')
    ];

    const match = titles.find(title => parseInt(title.id) === parseInt(this.title));

    return `${match?.title} ${this.firstname} ${this.lastname}`
  };

  get isCompany() {
    return this.type.toLowerCase() === 'company'
  };

  toJS = () => {
    return {
      email: this.email,
      firstname: this.firstname,
      lastname: this.lastname,
      locale: this.locale,
      mobile: this.mobile,
      title: this.title,
      devices: this.devices,
      device_groups: this.device_groups,
      credit_info: this.credit_info,
      address1: this.address1,
      address2: this.address2,
      address3: '',
      address_country: this.address_country,
      vat: this.vat.replace(/[. ,-]/g,''),
      type: this.type.toLowerCase(),
      company_name: this.company_name,
      is_company: this.valid_vat,
      name: this.valid_vat ? this.company_name : this.name,
      purchaseOrder: this.purchaseOrder,
      imp_enabled: this.imp_enabled,
    };
  };
}

decorate(Account, {
  displayName: computed,
  isCompany: computed
});


