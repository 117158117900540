import React from 'react';
import { FormattedMessage } from 'react-intl';

const Label = ({ htmlFor, ...rest }) => {
  return (
    <label htmlFor={htmlFor} {...rest}>
      <FormattedMessage id={htmlFor} />
    </label>
  )
}

export default Label;
