import { decorate, observable } from 'mobx';
import { doFetch, fetchV1, URL, controlOnErrors } from '../utils';
import { Site, DeviceClaim, Option, Device, SharedUser, Account } from '../components/models';

const URLS = {
  CLAIM: `${URL}/capi/devices/claim`,
  REPLACE_DEVICE: serialno => `${URL}/capi/devices/${serialno}/replace`,
  GET_ALL_SITES: `${URL}/capi/sites/account`,
  GET_SITE_BY_ID: id => `${URL}/capi/sites/${id}`,
  GET_DEVICES_FROM_SITE: id => `${URL}/capi/sites/${id}/devices`,
  GET_SHARED_USERS: id => `${URL}/capi/sites/${id}/shares`,
  UPDATE_SITE: id => `${URL}/capi/sites/${id}`,
  DELETE_SITE: id => `${URL}/capi/sites/${id}`,
  UPDATE_DEALER_ACCESS_SETTINGS: id => `${URL}/capi/sites/${id}/dealer_settings`,
  UPDATE_NOTIFICATION_SETTINGS: id => `${URL}/capi/sites/${id}/notification_settings`,
  LEAVE_SITE: id => `${URL}/capi/sites/${id}/leave`,
  ADD_SITE: `${URL}/capi/sites`,
  ADD_SHARED_USER: id => `${URL}/capi/sites/${id}/invite_account`,
  DELETE_SHARED_USER: (id, user) => `${URL}/capi/sites/${id}/remove_account/${user}`,
  TRANSFER_OWNERSHIP: (id) => `${URL}/capi/sites/${id}/transfer_ownership`,
  CANCEL_TRANSFER_OWNERSHIP: (id) => `${URL}/capi/sites/${id}/transfer_ownership`,
  RESEND_SHARED_USER_INVITE: id => `${URL}/capi/user/account/shares/invite/${id}`,
  DELETE_DEVICE: serialno => `${URL}/capi/devices/${serialno}/decouple`,
};

class SiteStore {
  constructor() {
    this.sites = [];
    this.sharedUsers = [];
    this.devices = [];

    this.newSite = new Site(this);
    this.selectedSite = new Site(this);
    this.newDevice = new DeviceClaim(this);
    this.selectedDevice = new Device(this);
    this.newSharedUser = new SharedUser();
    this.selectedSharedUser = undefined;
    this.newAdmin = new Account(this);

    this.loading = false;

    this.memberConnectionMethods = [
      new Option(this, 0, 'connection_method_lan'),
      new Option(this, 1, 'connection_method_bluecherry')
    ];

    this.memberResolveMechanisms = [
      new Option(this, 0, 'resolve_mechanism_static'),
      new Option(this, 1, 'resolve_mechanism_dns'),
      new Option(this, 2, 'resolve_mechanism_bluecherry'),
      new Option(this, 3, 'resolve_mechanism_dpt_network_discovery')
    ];
  }

  claimDevice = async () => {
    await doFetch(URLS.CLAIM, 'POST', { site: this.selectedSite.id, ...this.newDevice.toJS() });
    this.newDevice = new DeviceClaim(this);
  };

  replaceDevice = async () => {
    const { serialno } = this.selectedDevice;
    await doFetch(URLS.REPLACE_DEVICE(serialno), 'POST', { site: this.selectedSite.id, ...this.newDevice.toJS() });
  };

  getDevicesFromSite = async site => {
    const devices = await doFetch(URLS.GET_DEVICES_FROM_SITE(site));
    this.selectedSite.devices = devices.map(device => new Device(this, device));
  };

  resetDevice = () => {
    this.newDevice = new DeviceClaim(this);
  };


  resetSharedUser = () => {
    this.newSharedUser = new SharedUser();
  };

  setSelectedSite = site => {
    this.selectedSite = site;
  };

  addSite = async () => {
    const site = await doFetch(URLS.ADD_SITE, 'POST', this.newSite.toJS())
    this.sites.push(new Site(this, site));
    this.newSite = new Site(this);
  };

  updateOfflineNotificationsSettings = async () => {
    const { offline_notifications_enabled } = this.selectedSite;

    const body = {
      offline_notifications_enabled,
    };

    return await doFetch(URLS.UPDATE_NOTIFICATION_SETTINGS(this.selectedSite.id), 'PUT', body);
  };

  updateDealerAccess = async () => {
    const {
      dealer_access,
      da_expires_at
    } = this.selectedSite;

    const duration = parseInt(parseInt(da_expires_at) - (Date.now() / 1000));

    const body = {
      dealer_access,
      duration: parseInt(da_expires_at) === -1 ? da_expires_at : duration
    };

    return await doFetch(URLS.UPDATE_DEALER_ACCESS_SETTINGS(this.selectedSite.id), 'PUT', body);
  };

  updateSite = async () => {
    await controlOnErrors(this.selectedSite);
    return await doFetch(URLS.UPDATE_SITE(this.selectedSite.id), 'PUT', this.selectedSite.toJS());
  };

  leaveSite = async password => {
    const { id } = this.selectedSite;

    await doFetch(URLS.LEAVE_SITE(id), 'DELETE', {
      password
    });
  };

  deleteSite = async password => {
    const { id } = this.selectedSite;

    await doFetch(URLS.DELETE_SITE(id), 'DELETE', {
      password
    });

    this.sites = this.sites.filter(site => site.id !== id);
  };

  getById = async (id) => {
    const { body: site, statusCode } = await fetchV1(URLS.GET_SITE_BY_ID(id), 'GET')

    if (statusCode === 404 || statusCode === 401) {
      return false;
    }

    this.selectedSite = new Site(this, site);
    return true;
  };

  getAll = async () => {
    this.loading = true;
    const sites = await doFetch(URLS.GET_ALL_SITES, 'GET')
    this.sites = sites.sort((a, b) => a.name.localeCompare(b.name)).map(site => new Site(this, site));
    this.loading = false;
  };

  deleteSharedUser = async user => {
    const { id } = this.selectedSite;
    user.invited = false;
    user.state = null;
    await doFetch(URLS.DELETE_SHARED_USER(id, user.account), 'DELETE');

    this.sharedUsers = this.sharedUsers.filter(sharedUser => {
      return sharedUser.account !== user.account
    });
  };

  transferSite = async (password, email) => {
    const { id } = this.selectedSite;

    const { transferinfo } = await doFetch(URLS.TRANSFER_OWNERSHIP(id), 'POST', {
      password,
      email
    });

    this.selectedSite.transferinfo = transferinfo;
  };

  getSharedUsers = async (id) => {
    const sharedUsers = await doFetch(URLS.GET_SHARED_USERS(id), 'GET')

    sharedUsers.forEach(sharedUser => {
      sharedUser.name =
        (sharedUser.firstname === '' || sharedUser.lastname === '')
          ? `${sharedUser.firstname} ${sharedUser.lastname}`
          : sharedUser.email
    });

    this.sharedUsers = sharedUsers.map(user => new SharedUser(user));
  };

  resendInvite = sharedUser => {
    const { id } = this.selectedSite;
    return doFetch(URLS.RESEND_SHARED_USER_INVITE(id, sharedUser.shared_account), 'POST');
  };

  resetSharedUser = () => {
    this.newSharedUser = new SharedUser();
  };

  addSharedUser = async (sharedUser, addToSharedUsers) => {
    const { id } = this.selectedSite;
    const { email, account_id } = await doFetch(URLS.ADD_SHARED_USER(id), 'POST', { email: sharedUser.email });

    console.log({
      email,
      account: account_id
    })
    if (addToSharedUsers) {
      this.sharedUsers.push(new SharedUser({ email, account: account_id, state: 'SHARED', shared: true }));
      this.newSharedUser = new SharedUser();
    } else {
      sharedUser.email = email;
      sharedUser.account = account_id;
    }
  };

  deleteDevice = async (password) => {
    const device = this.selectedDevice;

    await doFetch(URLS.DELETE_DEVICE(device.serialno), 'DELETE', {
      site: this.selectedSite.id,
      moduleid: `${device.dev_id}${device.type_id}`,
      serialno: device.serialno,
      password
    });

    this.selectedSite.devices = this.selectedSite.devices.filter(device => device.serialno !== this.selectedDevice.serialno);
  };

  cancelTransfer = async () => {
    const { id } = this.selectedSite;
    await doFetch(URLS.CANCEL_TRANSFER_OWNERSHIP(id), 'DELETE');
    this.selectedSite.transferinfo = '';
  };

}

decorate(SiteStore, {
  sites: observable,
  devices: observable,
  sharedUsers: observable,
  newSharedUser: observable,
  selectedSharedUser: observable,
  newSite: observable,
  selectedSite: observable,
  selectedDevice: observable,
  newDevice: observable,
  newAdmin: observable,
  memberConnectionMethods: observable,
  memberResolveMechanisms: observable,
  loading: observable
});

export default new SiteStore();
