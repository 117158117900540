import React from 'react';
import { Input, Label } from '../elements/';
import {useObserver} from 'mobx-react-lite';

const ResetPasswordForm = ({ entity }) => {
  return useObserver(() => {
    return (
      <>
        <div className="input">
          <Label htmlFor="oldPassword" />
          <Input type="password" entity={entity} name="oldPassword" placeholder="placeholder_old_password"/>
        </div>

        <div className="input">
          <Label htmlFor="newPassword" />
          <Input type="password" entity={entity} name="newPassword" placeholder="placeholder_new_password"/>
        </div>

        <div className="input">
          <Label htmlFor="repeatPassword" />
          <Input type="password" entity={entity} name="repeatPassword" placeholder="placeholder_repeat_password"/>
        </div>
      </>
    )
  })
}

export default ResetPasswordForm;

