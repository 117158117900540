import React from 'react';
import { useObserver } from 'mobx-react-lite';
import { setValue, getValue, validate, getErrorMessage } from './IO';

const Checkbox = props => { 
  const {
    style = 'square',
    unSelectedColor,
    disabled = false,
    onClick,
    ...rest
  } = props;

  const onChange = ev => {
    if (disabled) {
      ev.preventDefault();
    } else {
      setValue(props, ev.target.checked);
      validate(props, ev.target.checked);
    }
  };

  const checkDisabled = ev => {
    if (disabled) {
      if (onClick !== undefined) {
        onClick()
      }
      ev.stopPropagation();
    }
  };

  const getStatus = () => {
    const { error } = getErrorMessage(props) || {};

    if (error !== undefined && error) {
      return {
        hasError: true
      }
    } else {
      return {
        hasError: false
      }
    }
  };

  return useObserver(() => { 
    const { hasError } = getStatus();

    return (
      <label className={`container ${style}`}>
        <input 
          type="checkbox" 
          name="checkbox" 
          onChange={onChange}
          onClick={checkDisabled}
          value={getValue(props)}
          checked={getValue(props)}
          {...rest}
        />
        <span 
          className={`checkmark ${hasError && 'invalid'}`} 
          style={{backgroundColor: unSelectedColor}}
        />
      </label>
    )
  })
}
export default Checkbox;
