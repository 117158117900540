import { decorate, observable } from 'mobx';

class Title {
  constructor(id, title) {
    this.id = id;
    this.title = title;
  };
};

decorate(Title, {
  title: observable,
  id: observable
});

export default Title;
