import React from 'react';
import { Switch, Route, BrowserRouter, Redirect } from 'react-router-dom';
import { Home, AccountDetails, DeviceOverview, AcceptInvite, DeviceLogin, AcceptOwnership, SiteOverview, AcceptNotification, DeclineNotification, Legal } from './pages';
import { getAccountInfo } from '../utils';
import Wrapper from './Wrapper';

const PrivateRoute = ({ component: Component, ...rest }) => {
  try {
    const { exp } = getAccountInfo();
    const isJWTValid = exp > (Date.now() / 1000);

    if (isJWTValid) {
      return (
        <Route {...rest} render={(props) => <Component {...props} />} />
      );
    }
    window.location.href = 'https://sso.came.io';
  } catch (err) {
    window.location.href = 'https://sso.came.io';
  }
};

const Router = () => (
  <BrowserRouter>
    <Switch>
      <PrivateRoute exact path="/" component={Wrapper(Home, '70%')} />
      <PrivateRoute exact path="/account_settings" component={Wrapper(AccountDetails, '70%')} />

      <PrivateRoute exact path="/site_overview/:id" component={Wrapper(SiteOverview, '70%')} />
      <PrivateRoute exact path="/device_overview/:id" component={Wrapper(DeviceOverview, '70%')} />
      <PrivateRoute exact path="/device_overview/:id/:screen" component={Wrapper(DeviceOverview, '70%')} />
      <PrivateRoute exact path="/accept/invite/:site/:email/:token" component={Wrapper(AcceptInvite, '70%')} />
      <PrivateRoute exact path="/accept/transfer/:site/:email/:token" component={Wrapper(AcceptOwnership, '70%')} />

      <PrivateRoute exact path="/accept/notification/:id" component={Wrapper(AcceptNotification, '70%')} />
      <PrivateRoute exact path="/decline/notification/:id" component={Wrapper(DeclineNotification, '70%')} />
      <PrivateRoute exact path="/legal/:key" component={Wrapper(Legal, '70%')} />

      <Route exact path="/proxy/device/login/:token/:access_id" component={DeviceLogin} />
      <Route path="*" component={() => <Redirect to="/" />} />
    </Switch>
  </BrowserRouter>
);

export default Router;
