import React, { useState } from 'react';
import { useObserver } from 'mobx-react-lite';
import { Cropper } from '../partials';
import defaultImage from '../../assets/images/default.png';

const ImagePreview = ({ entity, inModal, file }) => {
  const [ changing, setChanging ] = useState(false);

  return useObserver(() => {
    const { image } = entity;

    return (
      <div>
        {
          changing && (
            <Cropper 
              entity={entity} 
              inModal={false} 
              selectedFile={file} 
            />
          )
        }

        {
          !changing && (
            <div 
              className="cropperSelect" 
              style={inModal ? {backgroundColor: '#fff'} : {}}
            >
              <img 
                src={image === '' ? defaultImage : image} 
                alt="cropped_result" 
                width="300px" 
              />
            </div>
          )
        }
      </div>
    )
  });
};

export default ImagePreview;
