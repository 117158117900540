import React from 'react';
import { useObserver } from 'mobx-react-lite';
import { FormattedMessage } from 'react-intl';

import { Modal, Info, Error, Label, Input, Button } from '../elements';
import { closeModal, controlOnErrors } from '../../utils';
import { useStores, useError, useRedirect } from '../../hooks';
import {Messages} from '../I18n';

const LeaveSiteModal = () => {
  const { siteStore, settingsStore } = useStores(['siteStore', 'settingsStore']);
  const { error, setError, clearError } = useError('');
  const { redirect } = useRedirect();

  const close = () => {
    closeModal('leaveSite');
  };

  const leaveSite = async () => {
    try {
      const { account } = settingsStore;
      await controlOnErrors(account, account.passwordValidation);
      await siteStore.leaveSite(account.password);
      Messages.success('left_site');
      account.reset();

      setTimeout(() => {
        redirect('/');
        close();
      }, 500);

    } catch (err) {
      if (err?.status) {
        switch (err?.status) {
          case 401:
            setError('invalid_password_supplied');
            break;
          default:
            setError('error_occured_in_the_server');
        }
      }
    }
  };

  const onClose = () => {
    clearError();
    const { account } = settingsStore;
    account.cancel();
  };

  return useObserver(() => {
    const { selectedSite } = siteStore
    const { account } = settingsStore;

    return (
      <Modal id="leaveSite" onClose={onClose}>
        <div className="modal-header">
          <h1>
            <FormattedMessage id="leave_site" values={{name: selectedSite.name}}/>
          </h1>
          <i className="fas fa-times" onClick={close} />
        </div>

        <div className="modal-content">
          <Info info="info_leave_site" />

          <Error error={error} activeErrors={account.activeErrors}/>

          <div>
            <Label htmlFor="enter_your_password"/>
            <Input type="password" entity={account} name="password" placeholder="enter_your_password" />
          </div>
        </div>

        <div className="modal-footer">
          <Button text="leave_site" onClick={leaveSite}/>
        </div>
      </Modal>
    )
  });
};

export default LeaveSiteModal;

