import React from 'react';
import ReactMapboxGl, { Layer, Feature } from 'react-mapbox-gl';

const Map = ReactMapboxGl({
  accessToken: 'pk.eyJ1IjoiamVuc3BhdXdlbHMiLCJhIjoiY2phZWFmNnh5MXliajJxbmlmaW9vaGV5YiJ9.9PpdFvYJbD6rXVkPu6F4ag',
});

const MapBoxMap = () => {
  return (
    <Map style="mapbox://styles/mapbox/streets-v8" center={[parseFloat(3.2708468), parseFloat(51.3133721)]} containerStyle={{ height: '400px', width: '100%' }}>
      <Layer type="symbol" id="marker" layout={{ 'icon-image': 'marker-11' }}>
        <Feature coordinates={[parseFloat(3.2708468), parseFloat(51.3133721)]} />
      </Layer>
    </Map>
  ) 
}

export default MapBoxMap;
