import React from 'react';
import { Modal, Button } from '../elements';
import { useObserver } from 'mobx-react-lite';
import { useStores } from '../../hooks';
import { closeModal } from '../../utils';

const Buttons = ({ buttons }) => {
  const onClick = ({ cb }) => {
    if (typeof cb === 'function' && cb !== undefined) {
      cb();
    };

    closeModal('warningModal');
  };

  return buttons.filter(button => !button.hide).map((button, index) => (
    <Button text={button.title} onClick={() => onClick(button)} key={index}/>
  ));
};

const WarningModal = () => {
  const { global } = useStores(['global']);

  const onClose = () => {
    const { buttons } = global;
    const match = buttons.find(button => button.title === 'cancel');

    if (match !== undefined && match.cb !== undefined) {
      match.cb();
    }

    closeModal('warningModal');
  };

  return useObserver(() => {
    const {warningTitle, warningMessage, buttons } = global;

    return (
      <Modal id="warningModal" onClose={onClose}>
        <div className="modal-header">
          <h1>{warningTitle}</h1>
          <i className="fas fa-times" onClick={onClose}/>
        </div>
        <div className="modal-content">
          <p>{warningMessage}</p>
        </div>
        <div className="modal-footer">
          <Buttons buttons={buttons} />
        </div>
      </Modal>
    )
  })
}

export default WarningModal;
