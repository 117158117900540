import { observable, decorate } from 'mobx';
import { URL, doFetch } from '../utils';
import { SharedUser, Device } from '../components/models';

const URLS = {
  GET_ALL_SHARED_USERS: `${URL}/api/user/account/shares/`,
  UPDATE_SHARED_USER: id => `${URL}/api/user/account/shares/${id}`,
  ADD_SHARED_USER: `${URL}/api/user/account/shares/`,
  DELETE_SHARED_USER: id => `${URL}/capi/user/account/shares/${id}`,
  RESEND_SHARED_USER_INVITE: id => `${URL}/api/user/account/shares/invite/${id}`,
  GET_SHARED_DEVICES_FROM_USER: email => `${URL}/api/user/account/shares/devices/${email}`
};

class SharedUsersStore {
  constructor() {
    this.sharedUsers = [];
    this.newSharedUser = new SharedUser();
    this.sharedUsersFromDevice = [];
    this.selectedSharedUser = undefined;
    this.sharedDevices = [];
    this.loaded = false;
  };

  deleteDeviceFromSharedDevices = serialno => {
    this.sharedDevices = this.sharedDevices.filter(device => {
      return device.serialno !== serialno
    });
  };

  addSharedUserWithoutUpdating = () => {
    this.sharedUsers.push(new SharedUser(this.newSharedUser.toJS()));
    this.sharedUsersFromDevice.push(new SharedUser(this.newSharedUser.toJS()));
    this.newSharedUser = new SharedUser();
  };

  resendInvite = sharedUser => {
    return doFetch(URLS.RESEND_SHARED_USER_INVITE(sharedUser.shared_account), 'POST');
  };

  resetSharedUser = () => {
    this.newSharedUser = new SharedUser();
  };

  addNewSharedUser = async () => {
    const sharedUser = await doFetch(URLS.ADD_SHARED_USER, 'POST', this.newSharedUser.toJS());
    this.sharedUsers.push(new SharedUser(sharedUser));
    this.newSharedUser = new SharedUser();
  };

  deleteSharedUser = async sharedUser => {
    await doFetch(URLS.DELETE_SHARED_USER(sharedUser.shared_account), 'DELETE')
    this.sharedUsers = this.sharedUsers.filter(user => {
      return user.shared_account !== sharedUser.shared_account
    });
  };

  getSharedDevicesFromSelectedUser = async () => {
    const { email } = this.selectedSharedUser;
    const devices = await doFetch(URLS.GET_SHARED_DEVICES_FROM_USER(email), 'GET');
    this.sharedDevices = devices.map(device => new Device(this, device));
  };

  getAllSharedUsers = async () => {
    const data = await doFetch(URLS.GET_ALL_SHARED_USERS, 'GET');
    this.sharedUsers = data.map(sharedUser => new SharedUser(sharedUser));
    return this.sharedUsers;
  };

  updateSharedUser = async sharedUser => {
    return doFetch(URLS.UPDATE_SHARED_USER(sharedUser.shared_account), 'PUT', sharedUser.toJS());
  };

  setSharedUsersFromDevice = sharedUsers => {
    this.sharedUsersFromDevice = sharedUsers.map(sharedUser => new SharedUser(sharedUser));
  };
}

decorate(SharedUsersStore, {
  sharedUsers: observable,
  newSharedUser: observable,
  sharedUsersFromDevice: observable,
  loaded: observable,
  selectedSharedUser: observable,
  sharedDevices: observable
});

export default new SharedUsersStore();

